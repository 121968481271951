import styled from "styled-components";

export const SwitchWrapper = styled.div<{
  $labelSide?: 'left' | 'right';
  $disableMargin?: boolean;
}>`
  display: flex;
  flex-direction: ${({ $labelSide }) => $labelSide === 'left' ? 'row-reverse' : 'row'};
  width: auto;
  margin-right:  ${({ $disableMargin }) => $disableMargin ? '0' : 'auto'};
  gap: 8px;
`;

export const SwitchLabel = styled.div<{
  $checked?: boolean;
}>`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ theme, $checked }) => $checked ? theme.neutralText : theme.neutralTextWeak};
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 17px;
`;

export const Slider = styled.span<{
  $error: boolean;
}>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ $error }) => $error ? '#C53660' : '#5F687714'};
  transition: 0.4s;
  border-radius: 25px;
  
  &:before {
    position: absolute;
    content: '';
    height: calc(100% - 6px);
    top: 50%;
    left: 1.5px;
    transform: translateY(-50%);
    aspect-ratio: 1/1;
    transition: 0.3s;
    border-radius: 50%;
  }

  input:checked + &::before {
    border: 2.5px solid white;
    background: ${({ $error }) => $error ? '#C53660' : '#00143F'};
  }

  input:checked + &:hover::before {
    background: white;
  }

  input:not(:checked) + &::before {
    border: 2.5px solid ${({ $error }) => $error ? '#C53660' : '#00143F'};
    background: white;
  }

  ${({ $error }) => $error ? `
    input:not(:checked) + & {
      background: #C536601F;
    }
  `: ``}

  

  input:not(:checked) + &:hover::before {
    background: ${({ $error }) => $error ? '#C53660' : '#00143F'};
  }

  input:checked + & {
    background-color: ${({ $error }) => $error ? '#C53660' : '#00143F'} ;
  }

  input:checked + &:before {
    left: 0;
    transform: translateX(calc(100% - 7px)) translateY(-50%);;
  }
`;

export const SwitchInput = styled.input<{
  $error?: boolean;
}>`
  ${({ $error }) => $error ?
    `
      ${Slider} {
        box-shadow: 0 0 1px #C536601F;
        border: none;
      }
    `
    :
    ''
  }
  opacity: 0;
  width: 0;
  height: 0;
`;