import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { createPortal } from "react-dom";
import _ from "lodash";
import {
  NumberValue,
  max,
  scaleLinear,
  scaleOrdinal,
  select,
  timeParse,
  timeFormat,
  range,
  curveLinearClosed,
  lineRadial,
} from "d3";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title,
} from "./styles";
import { HeadingNameAndButton } from "../styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { getActiveModal } from "../../../store/selectors/modals";

import { calculateNumTicks } from "../widgetHelpers";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { Tooltip } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { RadarChartGroupedData } from "./utils/getGroupData";
import { getCurrentColorV2 } from "../utils/getCurrentMarker";

export interface RadarChartProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  preview?: boolean;
}

const axisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

export const RadarChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  preview = false,
}: RadarChartProps) => {
  const dispatch = useDispatch();

  const svgRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure();
  const [measureRef, bounds] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<{
    name?: string;
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);

  const margin = { top: 25, right: 5, bottom: 25, left: 5 };
  const width = (bounds.width || 1085) - margin.left - margin.right;
  const height = (bounds.height || 300) - margin.top - margin.bottom;
  const outerRadius = Math.min(width, height) / 2;
  const rScaleSteps = 5;

  const parseDate = timeParse("%Y");
  const format = timeFormat("%Y");
  const formatDate = useCallback(
    (date: string) => format(parseDate(date) as Date),
    [format, parseDate]
  );

  const radarChartSuggestion = aiSuggestions?.filter(
    (chart: AiSuggestionsDto) => chart.chartType === "radarChart"
  )[0];

  const groupBy =
    currentWidget?.groupBy?.at(0) || radarChartSuggestion?.groupBy;

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : radarChartSuggestion.xAxe?.at(0);

  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : radarChartSuggestion.yAxe?.at(0);

  const groupedData: any = RadarChartGroupedData(currentWidget);

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const uniqueValues =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : Object.keys(groupedData);

  const legendValues = [];
  if (groupedData && uniqueValues?.length) {
    for (let i = 0; i < uniqueValues?.length; i++) {
      const dataKey = uniqueValues[i];
      const color = getCurrentColorV2(currentWidget, dataKey, styleId);
      legendValues.push({ label: dataKey!, key: dataKey!, color });
    }
  }

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  // Determine the series
  const seriesData = Object.keys(groupedData)
    .reverse()
    .map((key: string, index: number) => {
      const data = [...groupedData[key].map((d: any) => [0, d.y])];
      data["key" as any] = key;
      data["index" as any] = index;

      return data;
    });

  //* Scales
  const colorScale = scaleOrdinal<string, string>()
    .domain(legendValues?.map((item) => item.label))
    .range(legendValues?.map((item) => item.color))
    .unknown("#ccc");

  const maxValue = max(seriesData, (d) => max(d, (d) => d[1])) as number;

  const rScale = scaleLinear<number, number>()
    .range([0, outerRadius])
    .domain([0, maxValue])
    .nice();

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget.tooltip) && !recommended) {
        setTooltip({
          name: datum.key,
          data: {
            year: String(formatDate(datum[0])),
            value: String(datum[1] - datum[0]),
          },
          x: event.clientX - 27,
          y: event.clientY - 85,
        });
      }
    },
    [currentWidget.tooltip, formatDate, recommended, showTooltip]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip) {
      setTooltip(null);
    }
  }, [setTooltip, showTooltip]);

  const handleMouseOver = function (self: any, svg: any) {
    svg
      .selectAll(".radar-data-point, .radar-line-path-group, .radar-path-group")
      .transition()
      .duration(200)
      .attr("opacity", ".2");

    select(self).transition().duration(200).attr("opacity", "1").attr("r", 6);
  };

  const handleMouseOut = function (svg: any) {
    svg
      .selectAll(".radar-data-point, .radar-line-path-group, .radar-path-group")
      .transition()
      .duration(200)
      .attr("opacity", "1");

    svg
      .selectAll(".radar-data-point")
      .transition()
      .duration(200)
      .attr("opacity", "1")
      .attr("r", 4);
  };

  //* Chart
  const svgContainer = select(svgRef.current)
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom);

  useEffect(() => {
    if (svgContainer.select("g").size()) {
      return;
    }

    const categories =
      currentWidget.data?.reduce((t: any, l: any) => {
        if (!t?.includes(l[xAxe])) {
          return [...t, l[xAxe]];
        }
        return t;
      }, []) || [];

    const angleSlice = (Math.PI * 2) / categories.length;

    const svg = svgContainer
      .append("g")
      .attr(
        "transform",
        `translate(${(width + margin.left + margin.right) / 2}, ${
          (height + margin.top + margin.bottom) / 2
        })`
      );

    //* Grid
    const grid = svg.append("g").attr("class", "grid");
    grid
      .selectAll(".grid-polygon")
      .data(range(1, rScaleSteps + 1))
      .enter()
      .append("polygon")
      .attr("class", "grid-polygon")
      .attr("points", (d) => {
        const points = categories.map((c: string, i: number) => {
          const angle = angleSlice * i - Math.PI / 2;
          const x = rScale((d * maxValue) / rScaleSteps) * Math.cos(angle);
          const y = rScale((d * maxValue) / rScaleSteps) * Math.sin(angle);
          return `${x},${y}`;
        });
        return points.join(" ");
      })
      .style("fill", "none")
      .attr("stroke", "#939ba7");

    //* Axes
    const axisGrid = svg.append("g").attr("class", "axis-grid");
    categories.forEach((category: string, i: number) => {
      // INFO: Start from the top: angleSlice * i - Math.PI / 2
      const angle = angleSlice * i - Math.PI / 2;
      const x = rScale(maxValue) * Math.cos(angle);
      const y = rScale(maxValue) * Math.sin(angle);

      // Radial axis line
      axisGrid
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", x)
        .attr("y2", y)
        .attr("class", "axis")
        .style("stroke", "#939ba7")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-dasharray", "2,2");

      // Radial axis line's cap
      axisGrid
        .append("circle")
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", 3)
        .attr("class", "axis-cap")
        .style("fill", "#939ba7");

      // Axis labels
      axisGrid
        .append("text")
        .attr("class", "axis-label")
        .attr("x", (rScale(maxValue) + 12) * Math.cos(angle))
        .attr("y", (rScale(maxValue) + 12) * Math.sin(angle))
        .attr("text-anchor", "middle")
        .attr("font-size", "12")
        .attr("dominant-baseline", "central")
        .text(category);
    });

    //* Grid ticks
    const tick = svg.append("g").attr("class", "tick");
    tick
      .selectAll(".tick-rect")
      .data(range(1, rScaleSteps + 1))
      .enter()
      .append("text")
      .attr("class", "tick-rect")
      .attr("x", 0)
      .attr("y", (d) => -rScale((d * maxValue) / rScaleSteps))
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .text((d) => Math.floor((maxValue / rScaleSteps) * d).toString())
      .each(function (d, i) {
        const textNode = select(this);
        const textBBox = textNode.node()?.getBBox() as DOMRect;
        const padding = {
          x: 0,
          y: 0,
        };
        const rectWidth = textBBox.width + padding.x * 2;
        const rectHeight = textBBox.height + padding.y * 2;
        const rectX = textBBox.x - padding.x;
        const rectY = textBBox.y - padding.y;

        tick
          .append("rect")
          .attr("class", "label-rect")
          .attr("x", rectX)
          .attr("y", rectY)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", 4)
          .attr("ry", 4)
          .style("fill", "#f1f4f7");
      });

    // Tick label
    tick
      .selectAll(".tickl-abel")
      .data(range(1, rScaleSteps + 1))
      .enter()
      .append("text")
      .attr("class", "tick-label")
      .attr("x", 0)
      .attr("y", (d) => -rScale((d * maxValue) / rScaleSteps))
      .attr("font-size", "12px")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .text((d) => axisTickFormatter(Math.floor((maxValue / rScaleSteps) * d)));

    // * DataViz
    // Line for each series.
    svg
      .append("g")
      .attr("class", "radar-path-group")
      .selectAll()
      .data(seriesData)
      .join("path")
      .attr(
        "d",
        lineRadial()
          .angle((d, j) => angleSlice * j)
          .radius((d: any) => rScale(d[1]))
          .curve(curveLinearClosed) as any
      )
      .attr("stroke", (d: any) => colorScale(d.key) as string)
      .attr("stroke-width", 2)
      .attr("fill", (d: any) => colorScale(d.key) as string)
      .attr("fill-opacity", 0.15)
      .append("title")
      .text((d: any) => d.key);

    // Circle for each data point.
    svg
      .append("g")
      .attr("class", "radar-data-point-group")
      .selectAll()
      .data(seriesData)
      .join("g")
      .selectAll("circle")
      .data((d: any) => d.map((item: any) => ({ ...item, key: d.key })))
      .join("circle")
      .attr("class", "radar-data-point")
      .attr("cx", (d: any, i: number) => {
        const angle = angleSlice * i - Math.PI / 2;
        return rScale(d[1]) * Math.cos(angle);
      })
      .attr("cy", (d: any, i: number) => {
        const angle = angleSlice * i - Math.PI / 2;
        return rScale(d[1]) * Math.sin(angle);
      })
      .attr("r", 4)
      .attr("stroke", (d: any) => colorScale(d.key) as string)
      .attr("fill", "#fff")
      .attr("stroke-width", 1.5)
      .on("mouseover", function () {
        handleMouseOver(this, svg);
      })
      .on("mouseout", () => {
        handleMouseOut(svg);
      })
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);
  }, [
    svgContainer,
    margin.left,
    margin.top,
    numTicks,
    width,
    height,
    formatDate,
    colorScale,
    showTooltip,
    currentWidget.tooltip,
    recommended,
    groupedData,
    seriesData,
    handleMouseMove,
    handleMouseLeave,
    margin.right,
    margin.bottom,
    rScale,
    currentWidget.data,
    xAxe,
    maxValue,
  ]);

  if (_.isEmpty(groupedData)) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <></>}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["radarChart"]?.length + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 && showLegend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="unit"
            legendValues={legendValues}
          />
        )}
      </HeaderWrapper>

      <SVGStyled
        ref={(node) => {
          svgRef.current = node;
          measureRef(node);
        }}
        width="100%"
        height="100%"
      ></SVGStyled>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
          />,
          document.body
        )}
    </>
  );
};
