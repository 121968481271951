import styled from 'styled-components';
import { Sliders } from 'react-bootstrap-icons';

export const SVGStyled = styled.svg``;

export const HeaderWrapper = styled.div<{
  $isRow?: boolean;
}>`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  flex-direction: ${({ $isRow }) => ($isRow ? 'row' : 'column')};
  align-items: flex-start;
  gap: 8px;
`;

export const HeadingNameAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
`;

export const DatavizRecommendedCount = styled.div`
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  transition: all 0.15s linear;
`;

export const DatavizSettingsIcon = styled(Sliders)`
  height: 14px;
  transition: all 0.15s linear;
`;

export const SettingsButtonWrapper = styled.div<{
  $modalOpen?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 40px;
  padding: 1px 4px 1px 2px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 20px;
  cursor: pointer;
  background: #fff;
  box-shadow: ${({ $modalOpen }) =>
    $modalOpen ? 'none' : '0px 2px 4px 1px #141c2c0a'};
  transition: all 0.15s linear;

  ${DatavizRecommendedCount} {
    background-color: ${({ theme, $modalOpen }) =>
      $modalOpen ? theme.neutralBackground : theme.secondaryButton};
    color: ${({ theme, $modalOpen }) =>
      $modalOpen
        ? theme.neutralTextDisabled
        : theme.primaryTextStrong || '#fff'};
  }

  ${DatavizSettingsIcon} {
    fill: ${({ theme, $modalOpen }) =>
      $modalOpen ? theme.neutralTextDisabled : theme.neutralTextWeak};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.neutralBorderMedium};
    ${DatavizSettingsIcon} {
      fill: ${({ theme }) => theme.neutralText};
    }
  }
`;
