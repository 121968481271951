import { createSelector } from 'reselect';
import { StoreInterface } from "../../models";

const selectComparisonSlice = (state: StoreInterface) => state.comparison;

export const getComparisonName = createSelector(
  selectComparisonSlice,
  (comparisonSlice) => comparisonSlice?.comparisonName
);

export const getSelectedMeasure = createSelector(
  selectComparisonSlice,
  (comparisonSlice) => comparisonSlice?.selectedMeasure
);

export const getComparisonWidgets = createSelector(
  selectComparisonSlice,
  (comparisonSlice) => comparisonSlice?.widgets
);

export const getComparisonKips = createSelector(
  selectComparisonSlice,
  (comparisonSlice) => comparisonSlice?.kpis
);

export const getComparisonLoading = createSelector(
  selectComparisonSlice,
  (comparisonSlice) => comparisonSlice?.isLoading
);