import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { StoryTelling } from "../../components/Storytelling";
import {
  requestAllPages,
  requestPageById,
  requestPageWidgets,
  requestAllPublicPages,
  requestPublicPageById,
  requestPublicPageWidgets,
} from "../../store/slices/projectPages";
import {
  getAllPages,
  getCurrentPageWidgetsItems,
  getLayout,
  getPageSettings,
} from "../../store/selectors/projects";
import {
  requestCreateWidgets,
  requestWidgetsSuggestions,
} from "../../store/slices/widgets";
import {
  getIsPageTypeSelect,
  getIsPublicMode,
  getPageIdSelected,
  getReportActionType,
} from "../../store/selectors/main";
import { Layout } from "../../components/ProjectPageLayouts/Layout";
import { LAYOUTS } from "../../components/ProjectPageLayouts/config";
import { findFirstCommonElement } from "../../helpers/firstCommonElement";
import { adjustArray } from "../../helpers/ajustArray";
import {
  getLineChartColors,
  getLineChartMarkers,
} from "../../components/Widgets/LineChart/utils/getLineChartMarkers";
import {
  getLollipopChartColors,
  getLollipopChartMarkers,
} from "../../components/Widgets/Lollipop/utils/getLollipopChartMarkers";
import { getBarChartFormatting } from "../../components/Widgets/GroupedBarChart/utils/getKeys";
import {
  AreaChartDefaultMarkers,
  getAreaChartDefaultColors,
} from "../../components/Widgets/AreaChart/utils/getGroupData";
import { Loader } from "../../components/Loader";
import { defaultApparitionConfig, defaultNewWidget } from "./constants";
import { PageSettingsDTO } from "../../models/Pages";
import { SelectPageType } from "../../components/SelectPageType";
import WidgetSelector from "../../components/WigetSelector";
import { ReportConfiguration } from "../../components/Modals/ReportConfiguration";
import { ReportMain } from "../../components/Report";
import {
  setPageIdSelected,
  setReportActionType,
} from "../../store/slices/appConfig";
import {
  CreateBubbleWidgetDto,
  CreatePolarAreaWidgetDto,
  CreatePunchcardWidgetDto,
  CreateRadarWidgetDto,
  CreateScatterplotWidgetDto,
} from "../../models/Widgets";
import { extractBlockIds, LayoutI } from "../../helpers/extractBlockIds";
import { ComparisonMain } from "../../components/Comparison";

interface IProjectPage {
  downloadScreenshot?: (ref: React.RefObject<HTMLDivElement>) => void;
}

export const ProjectPage = ({
  downloadScreenshot = () => { },
}: IProjectPage) => {
  const isPublicRoute = useSelector(getIsPublicMode);
  const { id: queryId, pageId: queryPageId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pages = useSelector(getAllPages);
  const templateId = useSelector(getLayout);
  const widgets = useSelector(getCurrentPageWidgetsItems);
  const currentPage = useSelector(getPageSettings);
  const reportActionType = useSelector(getReportActionType);
  const isPageTypeSelect = useSelector(getIsPageTypeSelect);
  const pageIdSelected = useSelector(getPageIdSelected);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState<number | undefined>();

  const pageItems = useMemo(() => pages?.items, [pages?.items])
  const pageId = useMemo(() => queryPageId, [queryPageId])
  const id = useMemo(() => queryId, [queryId])

  useEffect(() => {
    if (isPublicRoute) {
      dispatch(requestAllPublicPages({ projectId: id }));
    } else {
      dispatch(requestAllPages({ projectId: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isPublicRoute]);

  useEffect(() => {
    if (typeof isPublicRoute === 'undefined' || !pageId) {
      return;
    }

    if (isPublicRoute) {
      if (!!pageItems?.find((page) => page.id === pageId)) {
        dispatch(requestPublicPageById({ id: pageId }));
        dispatch(
          requestPublicPageWidgets({ pageId: pageId, includeData: true })
        );
      } else if (pageItems?.length) {
        navigate("/projects/public/" + id + "/" + pageItems[0].id);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageId, isPublicRoute, pageItems]);

  useEffect(() => {
    if (!isPublicRoute && pageId) {
      dispatch(requestPageById({ id: pageId }));
      dispatch(requestPageWidgets({ pageId, includeData: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageId, isPublicRoute]);

  const getPageWidgetsRequest = useCallback(
    (page: PageSettingsDTO, suggestions: any) => {
      dispatch(
        requestPageWidgets({
          pageId: page.id!,
          includeData: true,
          callbacks: {
            onSuccess: (widgetItems) => {
              try {
                const timeout = (new Date().getTime() - (time || 0)) / 4;
                if (widgetItems?.count) {
                  setLoading(false);
                }

                if (!widgetItems?.count && page.dashType === "storytelling") {
                  let barChartRequestData: any[] = [];
                  let lineChartRequestData = [];
                  let lollipopChartRequestData = [];
                  let sankeyChartRequestData = [];
                  let mapChartRequestData = [];
                  let areaChartRequestData = [];
                  let matrixChartRequestData = [];
                  let polarAreaChartData = [];
                  let punchcardChartData = [];
                  let radarChartData = [];
                  let bubbleChartData = [];
                  let scatterplotChartData = [];

                  let suggestionsCopy = [...suggestions];
                  let chartTypes = suggestionsCopy.map((r: any) => r.chartType);
                  for (
                    let i = 2;
                    i < (suggestions.length > 8 ? 10 : suggestions.length + 2);
                    i++
                  ) {
                    const chartType =
                      suggestions[i - 2] && suggestions[i - 2].chartType;
                    if (chartType) {
                      const index = chartTypes.findIndex(
                        (c: string) => c === chartType
                      );
                      if (index !== -1) {
                        chartTypes.splice(index, 1);
                      }
                      const chart = suggestionsCopy?.find(
                        (widget: any) => widget.chartType === chartType
                      );
                      if (chart) {
                        const indexSuggestion = suggestionsCopy?.findIndex(
                          (widget: any) => widget.chartType === chartType
                        );
                        if (indexSuggestion !== -1) {
                          suggestionsCopy?.splice(indexSuggestion, 1);
                        }
                        switch (chart?.chartType) {
                          case "mapChart":
                            mapChartRequestData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i.toString(),
                              display: [],
                              arrangeBy: [],
                              datasetId: chart?.layers[0]?.datasetId,
                              styleId: chart.styleId || "default",
                              dataFormat: {},
                              uniqueValues: chart?.uniqueValues ?? [],
                            });
                            break;
                          case "areaChart":
                            areaChartRequestData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                            });
                            break;
                          case "lineChart":
                            lineChartRequestData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                            });
                            break;
                          case "barChart":
                            barChartRequestData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                            });
                            break;
                          case "lollipopChart":
                            lollipopChartRequestData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                            });
                            break;
                          case "sankeyChart":
                            const arrangeBy = [
                              ...(chart?.groupBy || []),
                              ...(chart?.xAxe || []),
                            ];
                            sankeyChartRequestData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                              display: chart.display || chart.yAxe,
                              arrangeBy: arrangeBy?.filter((r) => !!r),
                              palette: {
                                type: "sequential",
                                colorType: "discrete",
                                domain: ["Year", "Value"],
                                range: ["red", "blue"],
                                reverse: false,
                              },
                            });
                            break;
                          case "matrixChart":
                            matrixChartRequestData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                              dataFormat: {},
                              palette: {
                                type: "sequential",
                                colorType: "discrete",
                                domain: ["Year", "Value"],
                                range: ["red", "blue"],
                                reverse: false,
                              },
                            });
                            break;
                          case "polarAreaChart":
                            polarAreaChartData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                              dataFormat: {},
                              apparitionConfig: {},
                              uniqueValues: chart?.uniqueValues ?? {},
                              xAxe: chart?.xAxe || [],
                              yAxe: chart?.yAxe || [],
                            });
                            break;
                          case "radarChart":
                            radarChartData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                              dataFormat: {},
                              apparitionConfig: {},
                              uniqueValues: chart?.uniqueValues ?? {},
                              xAxe: chart?.xAxe || [],
                              yAxe: chart?.yAxe || [],
                            });
                            break;
                          case "punchcardChart":
                            punchcardChartData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                              dataFormat: {},
                              apparitionConfig: {},
                              uniqueValues: chart?.uniqueValues ?? {},
                              xAxe: chart?.xAxe || [],
                              yAxe: chart?.yAxe || [],
                            });
                            break;
                          case "scatterplotChart":
                            scatterplotChartData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                              dataFormat: {},
                              apparitionConfig: {},
                              uniqueValues: chart?.uniqueValues ?? {},
                              xAxe: chart?.xAxe || [],
                              yAxe: chart?.yAxe || [],
                            });
                            break;
                          case "bubbleChart":
                            bubbleChartData.push({
                              ...chart,
                              ...{
                                ...defaultNewWidget,
                                apparitionConfig: {
                                  ...defaultApparitionConfig,
                                  textAlignment: i % 2 !== 0 ? "right" : "left",
                                },
                              },
                              description:
                                chart?.description ||
                                defaultNewWidget.description,
                              insights:
                                chart?.insights || defaultNewWidget.insights,
                              blockId: i?.toString(),
                              dataFormat: {},
                              apparitionConfig: {},
                              uniqueValues: chart?.uniqueValues ?? {},
                              xAxe: chart?.xAxe || [],
                              yAxe: chart?.yAxe || [],
                            });
                            break;
                          default:
                        }
                      }
                    }
                  }

                  const existCharts =
                    barChartRequestData?.length +
                    lineChartRequestData?.length +
                    lollipopChartRequestData?.length +
                    mapChartRequestData?.length +
                    sankeyChartRequestData?.length +
                    areaChartRequestData?.length +
                    matrixChartRequestData?.length +
                    polarAreaChartData?.length +
                    radarChartData?.length +
                    scatterplotChartData?.length +
                    punchcardChartData?.length +
                    bubbleChartData?.length;

                  if (existCharts > 0) {
                    dispatch(
                      requestCreateWidgets({
                        barChart: barChartRequestData,
                        lineChart: lineChartRequestData,
                        lollipopChart: lollipopChartRequestData,
                        mapChart: mapChartRequestData,
                        sankeyChart: sankeyChartRequestData,
                        areaChart: areaChartRequestData,
                        matrixChart: matrixChartRequestData,
                        radarChart: radarChartData,
                        scatterplotChart: scatterplotChartData,
                        polarAreaChart: polarAreaChartData,
                        punchcardChart: punchcardChartData,
                        bubbleChart: bubbleChartData,
                        pageId,
                      })
                    );
                    setTimeout(() => {
                      setLoading(false);
                    }, timeout);
                  }
                }
                if (!widgetItems?.count && page?.dashType === "dashboard") {
                  const layout = LAYOUTS.find((l) => l.id === templateId);
                  let blocks: any = extractBlockIds(
                    layout?.arranging as LayoutI
                  );

                  blocks = adjustArray(blocks, layout?.numWidgets || 1);
                  let suggestionsCopy = [...suggestions];

                  let barChartRequestData: any[] = [];
                  let lineChartRequestData = [];
                  let lollipopChartRequestData = [];
                  let sankeyChartRequestData = [];
                  let mapChartRequestData = [];
                  let areaChartRequestData = [];
                  let matrixChartRequestData = [];
                  let scatterplotChartRequestData: CreateScatterplotWidgetDto[] =
                    [];
                  let radarChartRequestData: CreateRadarWidgetDto[] = [];
                  let punchcardChartRequestData: CreatePunchcardWidgetDto[] =
                    [];
                  let polarAreaChartRequestData: CreatePolarAreaWidgetDto[] =
                    [];
                  let bubbleChartRequestData: CreateBubbleWidgetDto[] = [];

                  let chartTypes = suggestions.map((r: any) => r.chartType);
                  for (let i = 0; i < blocks?.length; i++) {
                    const block = blocks[i];
                    const chartType = findFirstCommonElement(
                      chartTypes,
                      block.widgets
                    );
                    const index = chartTypes.findIndex(
                      (c: string) => c === chartType
                    );
                    if (index !== -1) {
                      chartTypes.splice(index, 1);
                    }
                    const chart = suggestionsCopy?.find(
                      (widget: any) => widget.chartType === chartType
                    );
                    if (chart) {
                      const indexSuggestion = suggestionsCopy?.findIndex(
                        (widget: any) => widget.chartType === chartType
                      );
                      if (indexSuggestion !== -1) {
                        suggestionsCopy?.splice(indexSuggestion, 1);
                      }
                      switch (chart?.chartType) {
                        case "mapChart":
                          mapChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            display: [],
                            arrangeBy: [],
                            palette: {},
                            datasetId: chart?.layers[0]?.datasetId,
                            apparitionConfig: {},
                            styleId: chart.styleId || "default",
                            dataFormat: {},
                            uniqueValues: chart?.uniqueValues ?? [],
                          });
                          break;
                        case "areaChart":
                          areaChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            apparitionConfig: {},
                            markers: AreaChartDefaultMarkers(chart),
                            formatting: getAreaChartDefaultColors(chart),
                            uniqueValues: chart?.uniqueValues ?? {},
                          });
                          break;
                        case "lineChart":
                          lineChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            apparitionConfig: {},
                            markers: getLineChartMarkers(chart),
                            formatting: getLineChartColors(chart),
                            uniqueValues: chart?.uniqueValues ?? {},
                          });
                          break;
                        case "barChart":
                          barChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            apparitionConfig: {},
                            formatting: getBarChartFormatting(chart),
                            uniqueValues: chart?.uniqueValues ?? {},
                          });
                          break;
                        case "lollipopChart":
                          lollipopChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            apparitionConfig: {},
                            markers: getLollipopChartMarkers(chart),
                            formatting: getLollipopChartColors(chart),
                            uniqueValues: chart?.uniqueValues ?? {},
                          });
                          break;
                        case "sankeyChart":
                          const arrangeBy = [
                            ...(chart?.groupBy || []),
                            ...(chart?.xAxe || []),
                          ];
                          sankeyChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            display: chart.display || chart.yAxe,
                            arrangeBy: arrangeBy?.filter((r) => !!r),
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                          });
                          break;
                        case "matrixChart":
                          matrixChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                          });
                          break;
                        case "polarAreaChart":
                          polarAreaChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "radarChart":
                          radarChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "punchcardChart":
                          punchcardChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "scatterplotChart":
                          scatterplotChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        case "bubbleChart":
                          bubbleChartRequestData.push({
                            ...chart,
                            blockId: block?.blockId?.toString(),
                            dataFormat: {},
                            apparitionConfig: {},
                            uniqueValues: chart?.uniqueValues ?? {},
                            xAxe: chart?.xAxe || [],
                            yAxe: chart?.yAxe || [],
                          });
                          break;
                        default:
                      }
                    }
                  }

                  const existCharts =
                    barChartRequestData?.length +
                    lineChartRequestData?.length +
                    lollipopChartRequestData?.length +
                    mapChartRequestData?.length +
                    sankeyChartRequestData?.length +
                    areaChartRequestData?.length +
                    matrixChartRequestData?.length +
                    polarAreaChartRequestData?.length +
                    radarChartRequestData?.length +
                    scatterplotChartRequestData?.length +
                    punchcardChartRequestData?.length +
                    bubbleChartRequestData?.length;

                  if (existCharts > 0) {
                    dispatch(
                      requestCreateWidgets({
                        barChart: barChartRequestData,
                        lineChart: lineChartRequestData,
                        lollipopChart: lollipopChartRequestData,
                        mapChart: mapChartRequestData,
                        sankeyChart: sankeyChartRequestData,
                        areaChart: areaChartRequestData,
                        matrixChart: matrixChartRequestData,
                        radarChart: radarChartRequestData,
                        scatterplotChart: scatterplotChartRequestData,
                        polarAreaChart: polarAreaChartRequestData,
                        punchcardChart: punchcardChartRequestData,
                        bubbleChart: bubbleChartRequestData,
                        pageId,
                      })
                    );
                    setTimeout(() => {
                      setLoading(false);
                    }, timeout);
                  }
                }
              } catch (e) {
                console.error(e);
              } finally {
                setTime(undefined);
                setLoading(false);
              }
            },
          },
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageId, time]
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!id || !pageId || pageId === "null" || isPublicRoute) return;
    setLoading(true);
    setTime(new Date().getTime());
    dispatch(
      requestPageById({
        id: pageId,
        callbacks: {
          onSuccess: (data) => {
            dispatch(
              requestWidgetsSuggestions({
                pageId,
                projectId: id,
                includeData: true,
                withStorytelling: false, //currentPage?.dashType === "storytelling",
                callbacks: {
                  onSuccess: (suggestions) => {
                    getPageWidgetsRequest(data, suggestions?.suggested_charts);
                  },
                },
              })
            );
          },
        },
      })
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  useEffect(() => {
    if (pageId) {
      if (widgets.length === 0) {
        setLoading(true);
      }
      if (widgets.length > 0) {
        setLoading(false);

        const delayedScreenshot = async () => {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          downloadScreenshot(ref);
        };

        delayedScreenshot();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets?.length, pageId]);

  const componentToRender = useMemo(() => {
    if (isPageTypeSelect && !pageIdSelected) {
      if (pages.currentPage.isLoading) {
        return <Loader blur={false} />;
      }
      return reportActionType === "create" ? (
        <WidgetSelector pages={pages} />
      ) : (
        <SelectPageType />
      );
    }
    if (reportActionType === "create") {
      return (
        <ReportConfiguration
          pageIdSelected={pageIdSelected}
          onClose={() => {
            dispatch(setPageIdSelected(null));
            dispatch(setReportActionType("unset"));
          }}
        />
      );
    }
    if (pages.count && currentPage?.dashType === "comparison") {
      return <ComparisonMain />;
    }
    if (pages.count && currentPage?.dashType === "report") {
      return (
        <>
          <ReportMain loading={loading} key={currentPage.id} />
          {reportActionType === "update" && (
            <ReportConfiguration
              onClose={() => {
                dispatch(setPageIdSelected(null));
                dispatch(setReportActionType("unset"));
              }}
            />
          )}
        </>
      );
    }
    if (pages.count && currentPage?.dashType === "dashboard") {
      return <Layout />;
    }
    if (pages.count && currentPage?.dashType === "storytelling") {
      return <StoryTelling key={currentPage.id} />;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage?.dashType,
    currentPage?.id,
    pages?.currentPage?.isLoading,
    isPageTypeSelect,
    reportActionType,
    loading,
    pages?.count,
    pageIdSelected,
  ]);

  return (
    <>
      {loading ? <Loader blur={false} /> : null}
      <div ref={ref} key={currentPage.id}>
        {componentToRender}
      </div>
    </>
  );
};
