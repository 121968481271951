import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { WidgetChartWrapper } from "../../Widgets";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getLayout,
  getPageSettings,
} from "../../../store/selectors/projects";
import { LAYOUTS } from "../config";
import { WIDGETS } from "../../Widgets/widgets";
import { checkFullProperty } from "../../../helpers/checkFullProperty";
import { requestPageKpis } from "../../../store/slices/projectPages";
import { KPISettingsWrapper } from "../../KPISettingsWrapper";

export const Layout = () => {
  const dispatch = useDispatch();
  const pageWidgetsState = useSelector(getCurrentPageWidgets);
  const currentPageState = useSelector(getPageSettings);
  const selectedLayout = useSelector(getLayout);
  const aiKpi = useSelector(getCurrentPageKpi);
  const [isFullTemplate, setIsFullTemplate] = useState(false);

  const pageWidgets = useMemo(() => pageWidgetsState, [pageWidgetsState]);
  const currentPageId = useMemo(
    () => currentPageState?.id,
    [currentPageState?.id]
  );

  const layout = useMemo(() => {
    let localLayout = LAYOUTS.find((r) => r.id === selectedLayout);
    if (!localLayout) {
      localLayout = LAYOUTS.find((r) => r.id === "3_8_c");
    }
    return localLayout;
  }, [selectedLayout]);

  useEffect(() => {
    const newFullTemplate = checkFullProperty(layout?.arranging);
    setIsFullTemplate(newFullTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.arranging]);

  const Column = ({
    size,
    children,
    scroll,
    isWidget,
    full = false,
    hasPadding = false,
  }: {
    size: number;
    scroll: boolean;
    isWidget: boolean;
    children: any;
    full?: boolean;
    hasPadding?: boolean;
  }) => {
    const [flexBasis, setFlexBasis] = useState(size);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setFlexBasis(12);
        } else {
          setFlexBasis(size);
        }
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, [size]);

    const width = (100 * flexBasis) / 12;

    let style: CSSProperties = {
      display: "flex",
      flexDirection: "column",
      height: `100%`,
      width: `${width}%`,
      gap: "8px",
      position: "relative",
      zIndex: 1,
    };
    if (full) {
      style = {
        ...style,
        width: `calc(100% - 40px)`,
        height: `calc(100% - 72px)`,
        position: "absolute",
        zIndex: 0,
      };
    }
    if (scroll) {
      style = {
        ...style,
        overflowY: "auto",
      };
    }
    if (hasPadding) {
      style = {
        ...style,
        padding: "8px",
      };
    }
    if (!isWidget) {
      style = {
        ...style,
        margin: "auto",
      };
    }
    return <div style={style}>{children}</div>;
  };

  const Row = ({ children, height }: { children: any; height?: string }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: height ? height : "100%",
        minHeight: "200px",
        width: "100%",
        gap: "8px",
      }}
    >
      {children}
    </div>
  );

  const getKpis = useCallback(() => {
    if (currentPageId) {
      dispatch(
        requestPageKpis({
          pageId: currentPageId,
          includeData: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId]);

  useEffect(() => {
    getKpis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId]);

  const renderLayout = (arranging: any) => {
    return (
      <>
        {arranging?.rows &&
          arranging?.rows.map((row: any, rowIndex: number) => {
            if (layout?.id === "3_8_c") {
              const isEmptyRow = row.columns
                .map((column: any) => {
                  const isMainRow = column.scroll;
                  if (isMainRow) return false;

                  let widget =
                    column?.blockId &&
                    pageWidgets?.items?.find(
                      (w) =>
                        w.blockId && parseInt(w.blockId) === column?.blockId
                    );

                  widget =
                    column?.blockId === 1 && aiKpi?.count > 0
                      ? column?.blockId === 1 && aiKpi?.count > 0
                      : widget;

                  return !widget;
                })
                .some((check: boolean) => check === true);

              if (isEmptyRow) return null;
            }

            return (
              <Row key={rowIndex} height={row.height}>
                {row.columns.map((column: any, colIndex: number) => {
                  const widget =
                    column?.blockId &&
                    pageWidgets?.items?.find(
                      (w) =>
                        w.blockId && parseInt(w.blockId) === column?.blockId
                    );
                  const ChartComponent =
                    widget && widget.chartType && WIDGETS[widget.chartType];
                  return (
                    <Column
                      key={colIndex}
                      size={column.size}
                      scroll={column.scroll}
                      full={
                        column.full &&
                        widget?.chartType === "mapChart" &&
                        widget.layout === "fullScreen"
                      }
                      hasPadding={column.hasPadding}
                      isWidget={column.rows?.length > 0}
                    >
                      {column.widgets &&
                      column.blockId === 1 &&
                      aiKpi?.count > 0 ? (
                        <WidgetChartWrapper
                          storytelling={false}
                          map={false}
                          isFullTemplate={isFullTemplate}
                        >
                          <KPISettingsWrapper
                            recommended={false}
                            storytelling={false}
                            kpis={aiKpi.items}
                          />
                        </WidgetChartWrapper>
                      ) : null}
                      {widget?.chartType &&
                      column.widgets &&
                      widget &&
                      ChartComponent ? (
                        <WidgetChartWrapper
                          storytelling={false}
                          map={widget.chartType === "mapChart"}
                          isFullTemplate={
                            isFullTemplate && widget.chartType !== "mapChart"
                          }
                        >
                          <ChartComponent
                            key={colIndex}
                            storytelling={false}
                            currentWidget={widget}
                            isFullScreen={column.full}
                          />
                        </WidgetChartWrapper>
                      ) : null}
                      {column.rows && renderLayout(column)}
                    </Column>
                  );
                })}
              </Row>
            );
          })}
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        height: "calc(100dvh - 64px - 72px - 20px)",
      }}
      key={layout?.id}
    >
      {layout && layout?.id && !!pageWidgets?.count
        ? renderLayout(layout?.arranging)
        : null}
    </div>
  );
};
