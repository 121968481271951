import React from "react";
import { StoryTellingSection } from "../..";
import { WIDGETS } from "../../../../../Widgets/widgets";
import { PlaceHolder } from "../PlaceHolder";
import { UpdateSingleWidgetDTO } from "../../../../../../models/Widgets";

interface Props {
  item: any;
  setContentItem: (data: UpdateSingleWidgetDTO) => void;
  draggable?: boolean;
}

const SectionItem: React.FC<Props> = ({ item, setContentItem, draggable }) => {
  const ChartComponent = item && WIDGETS[item.chartType];

  if (item.sectionType === "map") {
    return (
      <StoryTellingSection
        key={item.uniqueId}
        draggable={draggable}
        item={item}
        setContentItem={setContentItem}
        disabledSettings
      >
        {ChartComponent ? (
          <ChartComponent
            key={item.uniqueId}
            currentWidget={{ ...item }}
            uniqueMapId={item.uniqueId}
          />
        ) : (
          <PlaceHolder />
        )}
      </StoryTellingSection>
    );
  }
  if (item.sectionType === "widget") {
    return (
      <StoryTellingSection
        draggable={draggable}
        item={item}
        setContentItem={setContentItem}
      >
        {ChartComponent ? (
          <ChartComponent
            key={item.id}
            storytelling
            showLegend={true}
            currentWidget={{ ...item }}
          />
        ) : (
          <PlaceHolder />
        )}
      </StoryTellingSection>
    );
  }
  return <PlaceHolder />;
};

export default SectionItem;
