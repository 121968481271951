import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BarChartSteps, FileEarmark } from "react-bootstrap-icons";

import {
  removePage,
  requestCreatePage,
  requestRemovePage,
  resetCurrentPageData,
} from "../../store/slices/projectPages";
import { Dot, OptionsIcon, PageItem, PageName, PageOptions } from "./styles";
import {
  setPageIdSelected,
  setReportActionType,
} from "../../store/slices/appConfig";
import { setActiveModal } from "../../store/slices/modals";
import { getPageSettings } from "../../store/selectors/projects";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";
import { PageSubmenu } from "../PageSubmenu";

interface PageTabInterface {
  name: string;
  tabId: string;
  dashType: string;
  isPublic?: boolean
}

export const PageTab = ({ name, tabId, dashType, isPublic }: PageTabInterface) => {
  const isEditMode = useSelector(getIsEditMode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, pageId } = useParams();
  const isPublicRoute = useSelector(getIsPublicMode);
  const [menuOpen, setMenuOpen] = useState(false);
  const pageSettings = useSelector(getPageSettings);
  const {
    id: omitId,
    createdAt,
    updatedAt,
    ...duplicateSettings
  } = pageSettings;

  const onEdit = () => {
    navigate(`/projects/${id}/${tabId}`);
    dispatch(setActiveModal({ id: "projectSettingsModal" }));
  };

  const onRemove = () => {
    dispatch(removePage(tabId));
    dispatch(requestRemovePage({ id: tabId, navigate }));
  };

  const onDuplicate = () => {
    dispatch(requestCreatePage({ ...duplicateSettings, navigate }));
  };

  const onCreateComparison = () => {
    dispatch(setPageIdSelected(tabId));
    dispatch(setActiveModal({ id: "createComparisonModal" }))
  };

  return (
    <>
      <PageItem $active={tabId === pageId} $menuOpen={menuOpen}>
        {/* {isPublic && <GlobeIcon />} */}
        <PageName
          onClick={() => {
            dispatch(setPageIdSelected(null));
            dispatch(setReportActionType("unset"));
            if (tabId === pageId) return;
            dispatch(resetCurrentPageData());
            const path = isPublicRoute
              ? `/projects/public/${id}/${tabId}`
              : `/projects/${id}/${tabId}`;
            navigate(path);
          }}
        >
          {dashType === "report" ? (
            <FileEarmark width={16} />
          ) : (
            <BarChartSteps width={16} />
          )}
          {name}
        </PageName>
        {isPublic && <Dot />}
        {!isPublicRoute && isEditMode ? (
          <PageOptions
            onClick={() => {
              setMenuOpen((prevMenuOpen) => !prevMenuOpen);
            }}
          >
            <OptionsIcon />
          </PageOptions>
        ) : null}
        {menuOpen && (
          <PageSubmenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            onEdit={onEdit}
            onRemove={onRemove}
            onDuplicate={onDuplicate}
            onCreateComparison={onCreateComparison}

            pageTabMenu
            isReportTab={dashType === "report"}
          />
        )}
      </PageItem>
    </>
  );
};
