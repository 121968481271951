import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle } from "react-bootstrap-icons";

import { removeAllSelectedFiles } from "../../../store/slices/datasets";

import { closeActiveModal } from "../../../store/slices/modals";
import { LollipopChart } from "../../Widgets/Lollipop";
import { SankeyPlot } from "../../Widgets/Sankey";
import { DatasetsItem } from "../../DatasetsItem";
import { Button } from "../../Button";

import {
  ModalContainer,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
} from "../styles";
import {
  DatasetItem,
  DatasetsWrapper,
  OverviewContentExt,
  RecommendedWidgetItemWrapper,
  WidgetsCount,
  WidgetsItemsWrapper,
} from "./styles";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getCurrentProjectData,
  getCurrentProjectId,
  getPageSettings,
} from "../../../store/selectors/projects";
import { AreaChart } from "../../Widgets/AreaChart";
import {
  AiKpiDto,
  AiSuggestionsDto,
  CreateAreaChartWidgetDto,
  CreateBarWidgetDto,
  CreateBubbleWidgetDto,
  CreateLineChartWidgetDto,
  CreateLollipopChartWidgetDto,
  CreateMapWidgetDto,
  CreateMatrixChartWidgetDto,
  CreatePolarAreaWidgetDto,
  CreatePunchcardWidgetDto,
  CreateRadarWidgetDto,
  CreateSankeyChartWidgetDto,
  CreateScatterplotWidgetDto,
  UpdateWidgetDTO,
  WidgetItem,
} from "../../../models/Widgets";
import { WidgetChartWrapper } from "../../Widgets";
import { Barchart } from "../../Widgets/Barchart";
import {
  requestCreateAndUpdateWidgets,
  requestUpdateWidgets,
  requestWidgetsSuggestions,
} from "../../../store/slices/widgets";
import { WrapperLineChart } from "../../Widgets/LineChart";
import { MatrixChart } from "../../Widgets/MatrixChart";
import { LeafletMap } from "../../Widgets/LeafletMap";
import { Loader } from "../../Loader";
import { LAYOUTS } from "../../ProjectPageLayouts/config";
import { findFirstCommonElement } from "../../../helpers/firstCommonElement";
import {
  requestCreateKpis,
  requestPageKpis,
  requestPageWidgets,
  requestUpdatePage,
  setIsLoading,
  setPageWidgets,
} from "../../../store/slices/projectPages";
import { adjustArray } from "../../../helpers/ajustArray";
import {
  getLollipopChartColors,
  getLollipopChartMarkers,
} from "../../Widgets/Lollipop/utils/getLollipopChartMarkers";
import { getBarChartFormatting } from "../../Widgets/GroupedBarChart/utils/getKeys";
import {
  getLineChartColors,
  getLineChartMarkers,
} from "../../Widgets/LineChart/utils/getLineChartMarkers";
import {
  AreaChartDefaultMarkers,
  getAreaChartDefaultColors,
} from "../../Widgets/AreaChart/utils/getGroupData";
import { CloseModal } from "../DataVizSettings/styles";
import { defaultNewWidget } from "../../../pages/ProjectPage/constants";
import { getModalFirstItem } from "../../../store/selectors/modals";
import { KPIComponentWrapper } from "../../KPIComponentWrapper";
import { PolarAreaChart } from "../../Widgets/PolarAreaChart";
import { PunchcardChart } from "../../Widgets/PunchcardChart";
import { RadarChart } from "../../Widgets/RadarChart";
import { ScatterPlot } from "../../Widgets/ScatterPlot";
import { BubbleChart } from "../../Widgets/BubbleChart";
import { extractBlockIds, LayoutI } from "../../../helpers/extractBlockIds";

type ModalProps = {
  onClose: () => void;
};

export const PageRecommendedWidgets = ({ onClose }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const dispatch = useDispatch();
  const { datasets } = useSelector(getCurrentProjectData);
  const positionItem = useSelector(getModalFirstItem);
  const id = useSelector(getCurrentProjectId);
  const currentPage = useSelector(getPageSettings);
  const widgets = useSelector(getCurrentPageWidgets);
  const [isLoading, setLocalLoading] = useState(false);
  const [kpis, setKpis] = useState<AiKpiDto[]>([]);
  const [recommendedKpis, setRecommendedKpis] = useState<AiKpiDto[]>([]);
  const [recommendedWidgets, setRecommendedWidgets] = useState<any[]>([]);
  const [selectedCharts, setSelectedCharts] = useState<string[]>([]);
  const aiKpi = useSelector(getCurrentPageKpi);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      dispatch(removeAllSelectedFiles());
      if (currentPage?.id) {
        dispatch(
          requestPageKpis({
            pageId: currentPage?.id,
            includeData: true,
          })
        );
      }
      dispatch(closeActiveModal());
    }, 400);
  };

  const getKpis = useCallback(() => {
    if (id && currentPage?.id) {
      dispatch(
        requestPageKpis({
          pageId: currentPage?.id,
          includeData: true,
          callbacks: {
            onSuccess: (data) => {
              setKpis(data?.items || []);
            },
          },
        })
      );
    }
  }, [currentPage?.id, dispatch, id]);

  useEffect(() => {
    if (id && currentPage?.id) {
      getKpis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentPage?.id]);

  const getSuggestions = useCallback(() => {
    if (id && currentPage?.id) {
      dispatch(
        requestWidgetsSuggestions({
          pageId: currentPage?.id,
          projectId: id,
          includeData: true,
          callbacks: {
            onSuccess: (suggestions) => {
              setRecommendedWidgets(suggestions.suggested_charts);
              setRecommendedKpis(suggestions.kpis);
              setLocalLoading(false);
              dispatch(setIsLoading(false));
            },
          },
        })
      );
    }
  }, [currentPage?.id, dispatch, id]);

  useEffect(() => {
    if (id && currentPage?.id) {
      setLocalLoading(true);
      getSuggestions();
    }
  }, [id, currentPage?.id, getSuggestions]);

  const onSelectChart = (value?: string) => {
    const exist = selectedCharts?.some((r) => r === value);
    let charts = selectedCharts;
    if (exist) {
      charts = charts?.filter((r) => r !== value);
    } else {
      charts = value ? [value, ...charts] : charts;
    }
    setSelectedCharts(charts);
  };

  const renderRecommendedKpis = () => {
    if (kpis?.length) {
      return;
    }
    const firstKpi = recommendedKpis?.at(0);
    if (!firstKpi) {
      return;
    }

    const query = `${firstKpi.name}_${firstKpi.type}_${firstKpi.query}`;
    return (
      <KPIComponentWrapper
        kpi={firstKpi}
        recommended={true}
        selected={selectedCharts?.includes(query)}
        onSelect={() => onSelectChart(query)}
      />
    );
  };

  const renderRecommendedWidgets = () => {
    return recommendedWidgets?.map(
      (widget: AiSuggestionsDto, index: number) => {
        const query =
          widget.chartType === "mapChart"
            ? widget?.layers?.at(0)?.query
            : widget.query;
        const queryFirstPart = `${widget.name}_${widget.chartType}_${query}`;
        switch (widget.chartType) {
          case "mapChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  map={true}
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <LeafletMap
                    showLegend={false}
                    recommended={true}
                    selected={selectedCharts?.includes(queryFirstPart!)}
                    currentWidget={
                      {
                        ...widget,
                        id: widget.pageId,
                        display: [],
                        arrangeBy: [],
                        palette: {},
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "matrixChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <MatrixChart
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    showLegend={true}
                    currentWidget={
                      {
                        ...widget,
                        dataFormat: {},
                        palette: {},
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "lollipopChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <LollipopChart
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                        markers: getLollipopChartMarkers(widget),
                        formatting: getLollipopChartColors(widget),
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "barChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <Barchart
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                        formatting: getBarChartFormatting(widget),
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "sankeyPlot":
            const arrangeBy = [
              ...(widget?.groupBy || []),
              ...(widget?.xAxe || []),
            ];
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <SankeyPlot
                    showLegend={true}
                    recommended={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    currentWidget={
                      {
                        ...widget,
                        display: widget.display || widget.yAxe,
                        arrangeBy: arrangeBy?.filter((r) => !!r),
                        palette: {},
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "sankeyChart":
            const arrangeByChart = [
              ...(widget?.groupBy || []),
              ...(widget?.xAxe || []),
            ];
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <SankeyPlot
                    showLegend={true}
                    recommended={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    currentWidget={
                      {
                        ...widget,
                        display: widget.display || widget.yAxe,
                        arrangeBy: arrangeByChart?.filter((r) => !!r),
                        palette: {},
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "lineChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <WrapperLineChart
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                        markers: getLineChartMarkers(widget),
                        formatting: getLineChartColors(widget),
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "areaChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <AreaChart
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                        markers: AreaChartDefaultMarkers(widget),
                        formatting: getAreaChartDefaultColors(widget),
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "bubbleChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <BubbleChart
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "scatterplotChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <ScatterPlot
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "radarChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <RadarChart
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "punchcardChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <PunchcardChart
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          case "polarAreaChart":
            return (
              <RecommendedWidgetItemWrapper key={index}>
                <WidgetChartWrapper
                  recommended={true}
                  selected={selectedCharts?.includes(queryFirstPart)}
                  onSelect={() => onSelectChart(queryFirstPart)}
                >
                  <PolarAreaChart
                    showLegend={true}
                    selected={selectedCharts?.includes(queryFirstPart)}
                    recommended={true}
                    currentWidget={
                      {
                        ...widget,
                      } as unknown as WidgetItem
                    }
                  />
                </WidgetChartWrapper>
              </RecommendedWidgetItemWrapper>
            );
          default:
            return null;
        }
      }
    );
  };

  const updateWidgetsDashboard = () => {
    let barChartRequestData: CreateBarWidgetDto[] = [];
    let lineChartRequestData: CreateLineChartWidgetDto[] = [];
    let lollipopChartRequestData: CreateLollipopChartWidgetDto[] = [];
    let sankeyChartRequestData: CreateSankeyChartWidgetDto[] = [];
    let areaChartRequestData: CreateAreaChartWidgetDto[] = [];
    let matrixChartRequestData: CreateMatrixChartWidgetDto[] = [];
    let mapChartRequestData: CreateMapWidgetDto[] = [];
    let scatterplotChartRequestData: CreateScatterplotWidgetDto[] = [];
    let radarChartRequestData: CreateRadarWidgetDto[] = [];
    let punchcardChartRequestData: CreatePunchcardWidgetDto[] = [];
    let polarAreaChartRequestData: CreatePolarAreaWidgetDto[] = [];
    let bubbleChartRequestData: CreateBubbleWidgetDto[] = [];

    let barChartUpdateData: UpdateWidgetDTO[] = [];
    let lineChartUpdateData: UpdateWidgetDTO[] = [];
    let lollipopChartUpdateData: UpdateWidgetDTO[] = [];
    let sankeyChartUpdateData: UpdateWidgetDTO[] = [];
    let mapChartUpdateData: UpdateWidgetDTO[] = [];
    let areaChartUpdateData: UpdateWidgetDTO[] = [];
    let matrixChartUpdateData: UpdateWidgetDTO[] = [];
    let scatterplotChartUpdateData: UpdateWidgetDTO[] = [];
    let radarChartUpdateData: UpdateWidgetDTO[] = [];
    let punchcardChartUpdateData: UpdateWidgetDTO[] = [];
    let polarAreaChartUpdateData: UpdateWidgetDTO[] = [];
    let bubbleChartUpdateData: UpdateWidgetDTO[] = [];

    const layout = LAYOUTS.find((l) => l.id === currentPage.templateId);
    if (layout && selectedCharts?.length) {
      let newCharts = recommendedWidgets.filter((c) => {
        const query =
          c.chartType === "mapChart" ? c?.layers?.at(0)?.query : c.query;
        const queryFirstPart = `${c.name}_${c.chartType}_${query}`;
        return selectedCharts?.includes(queryFirstPart);
      });
      const newChartsType = newCharts?.map(
        (c: { chartType: any }) => c.chartType
      );
      const currentCharts = widgets?.items?.map((w) => w.chartType);
      let allCharts = [...currentCharts, ...newChartsType];
      const complexWidgets = allCharts?.filter((c) =>
        ["mapChart", "sankey", "sankeyChart"].includes(c)
      )?.length;
      const numWidgets =
        layout.numWidgets + selectedCharts?.length + (aiKpi?.count > 0 ? 1 : 0);
      let newLayout = LAYOUTS.find(
        (l) =>
          (l.complexity === layout.complexity ||
            l.complexity ===
              layout.complexity + (selectedCharts?.length >= 2 ? 2 : 1)) &&
          l.numWidgets === numWidgets &&
          l.numComplexWidgets === complexWidgets
      );
      if (!newLayout) {
        newLayout = LAYOUTS.find(
          (l) =>
            l.numWidgets === 8 &&
            l.numComplexWidgets === complexWidgets &&
            l.variant === "c"
        );
      }
      let blocks: any = extractBlockIds(layout?.arranging as LayoutI);
      let widgetsCopy = [...widgets?.items];

      if (blocks.length > widgetsCopy?.length + selectedCharts?.length) {
        blocks = adjustArray(
          blocks,
          widgetsCopy?.length + selectedCharts?.length
        );
      }
      for (let i = 0; i < blocks?.length; i++) {
        const block = blocks[i];

        const chartType = findFirstCommonElement(allCharts, block.widgets);
        if (chartType) {
          const index = allCharts.findIndex((c: string) => c === chartType);
          if (index !== -1) {
            allCharts.splice(index, 1);
          }
        }

        const widget = widgetsCopy?.find((w: any) => w.chartType === chartType);
        if (widget) {
          const indexWidget = widgetsCopy?.findIndex(
            (widget: any) => widget.chartType === chartType
          );
          if (indexWidget !== -1) {
            widgetsCopy?.splice(indexWidget, 1);
          }
          const blockId =
            aiKpi?.count > 0 ? block?.blockId + 1 : block?.blockId;
          switch (widget.chartType) {
            case "mapChart":
              mapChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "areaChart":
              areaChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "lineChart":
              lineChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "barChart":
              barChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "lollipopChart":
              lollipopChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "sankey":
              sankeyChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "matrix":
              matrixChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "polarAreaChart":
              polarAreaChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "punchcardChart":
              punchcardChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "radar":
              radarChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "scatterplot":
              scatterplotChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            case "bubbleChart":
              bubbleChartUpdateData.push({
                id: widget.id,
                blockId: blockId?.toString(),
              });
              break;
            default:
          }
        } else {
          const selectedChart = newCharts.find(
            (c: any) => c.chartType === chartType
          );
          newCharts = newCharts.filter((c: any) => c.chartType !== chartType);
          let descriptionChartData = {
            ...defaultNewWidget,
            description:
              selectedChart?.description || defaultNewWidget.description,
            insights: selectedChart?.insights || defaultNewWidget.insights,
          };
          const blockId =
            aiKpi?.count > 0 ? block?.blockId + 1 : block?.blockId;
          switch (selectedChart?.chartType) {
            case "mapChart":
              mapChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                display: [],
                arrangeBy: [],
                palette: {},
                datasetId: selectedChart?.layers[0]?.datasetId,
                styleId: selectedChart.styleId || "default",
                dataFormat: {},
                ...descriptionChartData,
              });
              break;
            case "areaChart":
              areaChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                ...descriptionChartData,
              });
              break;
            case "lineChart":
              lineChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                ...descriptionChartData,
              });
              break;
            case "barChart":
              barChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                ...descriptionChartData,
              });
              break;
            case "lollipopChart":
              lollipopChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                ...descriptionChartData,
              });
              break;
            case "sankeyChart":
              const arrangeBy = [
                ...(selectedChart?.groupBy || []),
                ...(selectedChart?.xAxe || []),
              ];
              sankeyChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                display: selectedChart?.display?.length
                  ? selectedChart.display
                  : selectedChart.yAxe,
                arrangeBy: arrangeBy?.filter((r) => !!r),
                palette: {},
                ...descriptionChartData,
              });
              break;
            case "matrixChart":
              matrixChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                dataFormat: {},
                palette: {},
                ...descriptionChartData,
              });
              break;
            case "scatterplotChart":
              scatterplotChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                dataFormat: {},
                palette: {},
                ...descriptionChartData,
              });
              break;
            case "radarChart":
              radarChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                dataFormat: {},
                palette: {},
                ...descriptionChartData,
              });
              break;
            case "punchcardChart":
              punchcardChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                dataFormat: {},
                palette: {},
                ...descriptionChartData,
              });
              break;
            case "polarAreaChart":
              polarAreaChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                dataFormat: {},
                palette: {},
                ...descriptionChartData,
              });
              break;
            case "bubbleChart":
              bubbleChartRequestData.push({
                ...selectedChart,
                blockId: blockId?.toString(),
                dataFormat: {},
                palette: {},
                ...descriptionChartData,
              });
              break;
            default:
          }
        }
      }
      const existCharts =
        barChartRequestData?.length +
        lineChartRequestData?.length +
        lollipopChartRequestData?.length +
        mapChartRequestData?.length +
        sankeyChartRequestData?.length +
        areaChartRequestData?.length +
        matrixChartRequestData?.length +
        barChartUpdateData?.length +
        lineChartUpdateData?.length +
        lollipopChartUpdateData?.length +
        sankeyChartUpdateData?.length +
        areaChartUpdateData?.length +
        matrixChartUpdateData?.length +
        mapChartUpdateData?.length +
        scatterplotChartRequestData?.length +
        scatterplotChartUpdateData?.length +
        radarChartRequestData?.length +
        radarChartUpdateData?.length +
        punchcardChartRequestData?.length +
        punchcardChartUpdateData?.length +
        polarAreaChartRequestData?.length +
        polarAreaChartUpdateData?.length +
        bubbleChartRequestData?.length +
        bubbleChartUpdateData?.length;

      if (currentPage?.id && existCharts > 0) {
        dispatch(setIsLoading(true));
        dispatch(setPageWidgets({ items: [], count: 0 }));
        if (existCharts > 0) {
          dispatch(
            requestCreateAndUpdateWidgets({
              createBarChart: barChartRequestData,
              createLineChart: lineChartRequestData,
              createLollipopChart: lollipopChartRequestData,
              createMapChart: mapChartRequestData,
              createSankeyChart: sankeyChartRequestData,
              createAreaChart: areaChartRequestData,
              createMatrixChart: matrixChartRequestData,
              barChart: barChartUpdateData,
              lineChart: lineChartUpdateData,
              lollipopChart: lollipopChartUpdateData,
              sankeyChart: sankeyChartUpdateData,
              areaChart: areaChartUpdateData,
              matrixChart: matrixChartUpdateData,
              mapChart: mapChartUpdateData,
              bubbleChart: bubbleChartUpdateData,
              scatterplotChart: scatterplotChartUpdateData,
              polarAreaChart: polarAreaChartUpdateData,
              punchcardChart: punchcardChartUpdateData,
              radarChart: radarChartUpdateData,
              createPolarAreaChart: polarAreaChartRequestData,
              createPunchcardChart: punchcardChartRequestData,
              createRadarChart: radarChartRequestData,
              createScatterplotChart: scatterplotChartRequestData,
              createBubbleChart: bubbleChartRequestData,
              pageId: currentPage?.id,
            })
          );
        }
        dispatch(
          requestUpdatePage({ ...currentPage, templateId: newLayout?.id! })
        );
        if (!existCharts) {
          dispatch(
            requestPageWidgets({
              pageId: currentPage?.id,
              includeData: true,
            })
          );
        }
        getSuggestions();
        handleOnClose();
      }
    }
  };

  const maxNumber = Math.max(...(widgets?.items).map((w) => Number(w.blockId)));

  const updateWidgetsStorytelling = () => {
    let barChartRequestData: CreateBarWidgetDto[] = [];
    let lineChartRequestData: CreateLineChartWidgetDto[] = [];
    let lollipopChartRequestData: CreateLollipopChartWidgetDto[] = [];
    let sankeyChartRequestData: CreateSankeyChartWidgetDto[] = [];
    let areaChartRequestData: CreateAreaChartWidgetDto[] = [];
    let matrixChartRequestData: CreateMatrixChartWidgetDto[] = [];
    let mapChartRequestData: CreateMapWidgetDto[] = [];
    let scatterplotChartRequestData: CreateScatterplotWidgetDto[] = [];
    let radarChartRequestData: CreateRadarWidgetDto[] = [];
    let punchcardChartRequestData: CreatePunchcardWidgetDto[] = [];
    let polarAreaChartRequestData: CreatePolarAreaWidgetDto[] = [];
    let bubbleChartRequestData: CreatePolarAreaWidgetDto[] = [];

    let barChartUpdateData: UpdateWidgetDTO[] = [];
    let lineChartUpdateData: UpdateWidgetDTO[] = [];
    let lollipopChartUpdateData: UpdateWidgetDTO[] = [];
    let sankeyChartUpdateData: UpdateWidgetDTO[] = [];
    let mapChartUpdateData: UpdateWidgetDTO[] = [];
    let areaChartUpdateData: UpdateWidgetDTO[] = [];
    let matrixChartUpdateData: UpdateWidgetDTO[] = [];
    let scatterplotChartUpdateData: UpdateWidgetDTO[] = [];
    let radarChartUpdateData: UpdateWidgetDTO[] = [];
    let punchcardChartUpdateData: UpdateWidgetDTO[] = [];
    let polarAreaChartUpdateData: UpdateWidgetDTO[] = [];
    let bubbleChartUpdateData: UpdateWidgetDTO[] = [];
    const newCharts = recommendedWidgets.filter((c) => {
      const query =
        c.chartType === "mapChart" ? c?.layers?.at(0)?.query : c.query;
      const queryFirstPart = `${c.name}_${c.chartType}_${query}`;
      return selectedCharts?.includes(queryFirstPart);
    });

    const isLastItem = positionItem === widgets.count + 1;
    newCharts.forEach((item, i) => {
      const selectedChart = item;
      let descriptionChartData = {
        ...defaultNewWidget,
        description: selectedChart?.description || defaultNewWidget.description,
        insights: selectedChart?.insights || defaultNewWidget.insights,
      };
    
      const blockId = String(maxNumber + 1 + i)
      switch (selectedChart?.chartType) {
        case "mapChart":
          mapChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            display: [],
            arrangeBy: [],
            palette: {},
            datasetId: selectedChart?.layers[0]?.datasetId,
            styleId: selectedChart.styleId || "default",
            dataFormat: {},
            ...descriptionChartData,
          });
          break;
        case "areaChart":
          areaChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            ...descriptionChartData,
          });
          break;
        case "lineChart":
          lineChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            ...descriptionChartData,
          });
          break;
        case "barChart":
          barChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            ...descriptionChartData,
          });
          break;
        case "lollipopChart":
          lollipopChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            ...descriptionChartData,
          });
          break;
        case "sankeyChart":
          const arrangeBy = [
            ...(selectedChart?.groupBy || []),
            ...(selectedChart?.xAxe || []),
          ];
          sankeyChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            display: selectedChart?.yAxe || [],
            arrangeBy: arrangeBy?.filter((r) => !!r),
            palette: {},
            ...descriptionChartData,
          });
          break;
        case "matrixChart":
          matrixChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            dataFormat: {},
            palette: {},
            ...descriptionChartData,
          });
          break;
        case "scatterplotChart":
          scatterplotChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            dataFormat: {},
            palette: {},
            ...descriptionChartData,
          });
          break;
        case "radarChart":
          radarChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            dataFormat: {},
            palette: {},
            ...descriptionChartData,
          });
          break;
        case "punchcardChart":
          punchcardChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            dataFormat: {},
            palette: {},
            ...descriptionChartData,
          });
          break;
        case "polarAreaChart":
          polarAreaChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            dataFormat: {},
            palette: {},
            ...descriptionChartData,
          });
          break;
        case "bubbleChart":
          bubbleChartRequestData.push({
            ...selectedChart,
            blockId: blockId,
            dataFormat: {},
            palette: {},
            ...descriptionChartData,
          });
          break;
        default:
      }
    });
    if (!isLastItem) {
      for (let widget of widgets.items) {
        const blockId =
          widget?.blockId && parseInt(widget?.blockId) >= positionItem
            ? parseInt(widget?.blockId) + newCharts?.length
            : parseInt(widget?.blockId!);
        switch (widget.chartType) {
          case "mapChart":
            mapChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "areaChart":
            areaChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "lineChart":
            lineChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "barChart":
            barChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "lollipopChart":
            lollipopChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "sankey":
            sankeyChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "matrix":
            matrixChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "polarAreaChart":
            polarAreaChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "punchcardChart":
            punchcardChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "radar":
            radarChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "scatterplot":
            scatterplotChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          case "bubbleChart":
            bubbleChartUpdateData.push({
              id: widget.id,
              blockId: blockId?.toString(),
            });
            break;
          default:
        }
      }
    }

    const existCharts =
      barChartRequestData?.length +
      lineChartRequestData?.length +
      lollipopChartRequestData?.length +
      mapChartRequestData?.length +
      sankeyChartRequestData?.length +
      areaChartRequestData?.length +
      matrixChartRequestData?.length +
      barChartUpdateData?.length +
      lineChartUpdateData?.length +
      lollipopChartUpdateData?.length +
      sankeyChartUpdateData?.length +
      areaChartUpdateData?.length +
      matrixChartUpdateData?.length +
      mapChartUpdateData?.length +
      scatterplotChartRequestData?.length +
      scatterplotChartUpdateData?.length +
      radarChartRequestData?.length +
      radarChartUpdateData?.length +
      punchcardChartRequestData?.length +
      punchcardChartUpdateData?.length +
      polarAreaChartRequestData?.length +
      polarAreaChartUpdateData?.length +
      bubbleChartRequestData?.length +
      bubbleChartUpdateData?.length;

    if (currentPage?.id && existCharts > 0) {
      dispatch(setIsLoading(true));

      if (existCharts > 0) {
        dispatch(
          requestCreateAndUpdateWidgets({
            createBarChart: barChartRequestData,
            createLineChart: lineChartRequestData,
            createLollipopChart: lollipopChartRequestData,
            createMapChart: mapChartRequestData,
            createSankeyChart: sankeyChartRequestData,
            createAreaChart: areaChartRequestData,
            createMatrixChart: matrixChartRequestData,
            barChart: barChartUpdateData,
            lineChart: lineChartUpdateData,
            lollipopChart: lollipopChartUpdateData,
            sankeyChart: sankeyChartUpdateData,
            areaChart: areaChartUpdateData,
            matrixChart: matrixChartUpdateData,
            mapChart: mapChartUpdateData,
            scatterplotChart: scatterplotChartUpdateData,
            polarAreaChart: polarAreaChartUpdateData,
            punchcardChart: punchcardChartUpdateData,
            radarChart: radarChartUpdateData,
            bubbleChart: bubbleChartUpdateData,
            createPolarAreaChart: polarAreaChartRequestData,
            createPunchcardChart: punchcardChartRequestData,
            createRadarChart: radarChartRequestData,
            createScatterplotChart: scatterplotChartRequestData,
            createBubbleChart: bubbleChartRequestData,
            pageId: currentPage?.id,
            callbacks: {
              onSuccess: () => {
                getSuggestions();
              },
            },
          })
        );
      }
      handleOnClose();
    }
  };

  const addKpis = () => {
    let barChartUpdateData: UpdateWidgetDTO[] = [];
    let lineChartUpdateData: UpdateWidgetDTO[] = [];
    let lollipopChartUpdateData: UpdateWidgetDTO[] = [];
    let sankeyChartUpdateData: UpdateWidgetDTO[] = [];
    let mapChartUpdateData: UpdateWidgetDTO[] = [];
    let areaChartUpdateData: UpdateWidgetDTO[] = [];
    let matrixChartUpdateData: UpdateWidgetDTO[] = [];
    let radarChartUpdateData: UpdateWidgetDTO[] = [];
    let scatterplotChartUpdateData: UpdateWidgetDTO[] = [];
    let punchcardChartUpdateData: UpdateWidgetDTO[] = [];
    let polarAreaChartUpdateData: UpdateWidgetDTO[] = [];
    let bubbleChartUpdateData: UpdateWidgetDTO[] = [];

    const blockIds = widgets.items
      ?.map((w) => (w.blockId ? parseInt(w.blockId) : 0))
      .sort();

    const firstBlockId = blockIds?.at(0);
    const lastBlockId =
      blockIds?.at(blockIds?.length - 1) || widgets.items?.length;
      
      for (let chart of widgets.items) {
        let blockId = chart.blockId ? parseInt(chart.blockId) : 0;
      if (currentPage?.dashType === "storytelling") {
        blockId = blockId + 1;
      }
      if (currentPage?.dashType === "dashboard" && blockId === firstBlockId) {
        blockId = lastBlockId + 1;
      }
      switch (chart?.chartType) {
        case "mapChart":
          mapChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "areaChart":
          areaChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "lineChart":
          lineChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "barChart":
          barChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "lollipopChart":
          lollipopChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "sankeyChart":
          sankeyChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "matrixChart":
          mapChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "polarAreaChart":
          polarAreaChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "punchcardChart":
          punchcardChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "radar":
          radarChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "scatterplot":
          scatterplotChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;
        case "bubbleChart":
          bubbleChartUpdateData.push({
            ...chart,
            blockId: blockId?.toString(),
          });
          break;

        default:
      }
    }

    const existCharts =
      barChartUpdateData?.length +
      lineChartUpdateData?.length +
      lollipopChartUpdateData?.length +
      sankeyChartUpdateData?.length +
      areaChartUpdateData?.length +
      matrixChartUpdateData?.length +
      mapChartUpdateData?.length +
      radarChartUpdateData?.length +
      scatterplotChartUpdateData?.length +
      punchcardChartUpdateData?.length +
      polarAreaChartUpdateData?.length +
      bubbleChartUpdateData?.length;

    if (currentPage?.id && existCharts > 0) {
      const existKpis = recommendedKpis.filter((c) => {
        const query = `${c.name}_${c.type}_${c.query}`;
        return selectedCharts?.includes(query);
      });
      const kpi = existKpis?.at(0);

      if (currentPage?.dashType === "dashboard") {
        const layout = LAYOUTS.find((l) => l.id === currentPage.templateId);
        let newLayout = LAYOUTS.find(
          (l) =>
            l.numWidgets === layout?.numWidgets! + 1 &&
            l.numComplexWidgets === layout?.complexWidgets?.length &&
            l.variant === layout.variant
        );

        if (!newLayout) {
          newLayout = LAYOUTS.find(
            (l) =>
              l.numWidgets === 8 &&
              l.numComplexWidgets === layout?.complexWidgets?.length &&
              l.variant === "c"
          );
        }
        dispatch(
          requestUpdatePage({
            ...currentPage,
            templateId: newLayout?.id!,
          })
        );
      }

      dispatch(setIsLoading(true));
      dispatch(setPageWidgets({ items: [], count: 0 }));
      dispatch(requestCreateKpis({ ...kpi!, pageId: currentPage?.id }));
      if (existCharts > 0) {
        dispatch(
          requestUpdateWidgets({
            barChart: barChartUpdateData,
            lineChart: lineChartUpdateData,
            lollipopChart: lollipopChartUpdateData,
            sankeyChart: sankeyChartUpdateData,
            areaChart: areaChartUpdateData,
            matrixChart: matrixChartUpdateData,
            mapChart: mapChartUpdateData,
            scatterplotChart: scatterplotChartUpdateData,
            polarAreaChart: polarAreaChartUpdateData,
            punchcardChart: punchcardChartUpdateData,
            radarChart: radarChartUpdateData,
            bubbleChart: bubbleChartUpdateData,
            pageId: currentPage?.id,
          })
        );
      }
    }
    handleOnClose();
  };

  const addNewChart = () => {
    const existKpis = recommendedKpis.filter((c) => {
      const query = `${c.name}_${c.type}_${c.query}`;
      return selectedCharts?.includes(query);
    });
    if (currentPage?.dashType === "storytelling") {
      updateWidgetsStorytelling();
    } else {
      updateWidgetsDashboard();
    }
    if (existKpis?.length) {
      addKpis();
    }
  };

  return (
    <ModalOverlay
      $centred={true}
      $isClosing={isClosing}
      onClick={handleOnClose}
    >
      <ModalWrapper
        $recommendedWidgetsStyles
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeadingWrapper>
          <ModalContainer>
            <ModalHeading> Recommended Widgets</ModalHeading>
            <WidgetsCount>{recommendedWidgets?.length}</WidgetsCount>
          </ModalContainer>
          <CloseModal onClick={handleOnClose} />
        </ModalHeadingWrapper>
        <OverviewContentExt>
          <DatasetsWrapper>
            <DatasetItem>Parameters</DatasetItem>
            {datasets.map((dataset) => {
              return (
                <DatasetsItem
                  disablePreview
                  dataset={dataset}
                  key={dataset.id}
                />
              );
            })}
          </DatasetsWrapper>
          <WidgetsItemsWrapper>
            {isLoading && <Loader blur={false} />}
            {renderRecommendedKpis()}
            {renderRecommendedWidgets()}
          </WidgetsItemsWrapper>
        </OverviewContentExt>

        <ModalFooterWrapper>
          <Button
            name="Close"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Button
            name="Submit"
            onClick={() => addNewChart()}
            variant={"secondary"}
            disabled={!(selectedCharts && selectedCharts?.length > 0)}
            size="medium"
            icon={<CheckCircle />}
          />
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
