// Auth
export const SIGN_IN_URL = "/auth/login";
export const REGISTER_URL = "/auth/register";
export const RESET_PASSWORD = "/auth/reset-password";
export const REFRESH_TOKEN_URL = "/auth/refresh-token";

// Projects
export const GET_ALL_PROJECTS = "/projects";
export const CREATE_PROJECT = "/projects";
export const GET_PROJECT_BY_ID = "/projects/{id}";
export const UPDATE_PROJECT_BY_ID = "/projects/{id}";
export const DELETE_PROJECT_BY_ID = "/projects/{id}";

// Datasets
export const GET_DATASETS = "/datasets";
export const CREATE_DATASET = "/datasets";
export const GET_DATASET_CONTENT_BY_ID = "/datasets/{id}/contents";
export const PATCH_DATASET_BY_ID = "/datasets/{id}";
export const DELETE_DATASET_BY_ID = "/datasets/{id}";
export const UPDATE_DATASET_BY_ID = "/datasets/{id}";

// Files
export const GET_FILE_SIGNED = "/files/download";
export const UPLOAD_FILE = "/files/upload";
export const DELETE_FILE = "/files";

// Pages
export const GET_PAGES = "/pages";
export const CREATE_PAGE = "/pages";

// Widgets
export const GET_WIDGETS = "/widgets";

export const GET_WIDGETS_SUGGESTIONS = "/suggestions";
export const GET_WIDGETS_STORIES = "/suggestions/stories";
export const GET_WIDGETS_BANNER = "/suggestions/banner";

export const CREATE_MAP_WIDGET = "/widgets/map";
export const CREATE_AREA_CHART_WIDGET = "/widgets/area-chart";
export const CREATE_LINE_CHART_WIDGET = "/widgets/line-chart";
export const CREATE_LOLLIPOP_CHART_WIDGET = "/widgets/lollipop-chart";
export const CREATE_BAR_CHART_WIDGET = "/widgets/bar-chart";
export const CREATE_SANKEY_CHART_WIDGET = "/widgets/sankey-chart";
export const CREATE_MATRIX_CHART_WIDGET = "/widgets/matrix-chart";
export const CREATE_MAP_CHART_WIDGET = "/widgets/map";
export const CREATE_SCATTERPLOT_CHART_WIDGET = "/widgets/scatterplot-chart";
export const CREATE_RADAR_CHART_WIDGET = "/widgets/radar-chart";
export const CREATE_POLAR_AREA_CHART_WIDGET = "/widgets/polar-area-chart";
export const CREATE_PUNCHCARD_CHART_WIDGET = "/widgets/punchcard-chart";
export const CREATE_BUBBLE_CHART_WIDGET = "/widgets/bubble-chart";

export const GET_SECTIONS = "/sections";
export const CREATE_SECTIONS = "/sections";
export const PATCH_SECTION_BY_ID = "/sections/{id}";
export const REMOVE_SECTION_BY_ID = "/sections/{id}";

// Kpis
export const CREATE_KPIS = "/kpis";

// Comparison

export const GET_COMPARISON = "/comparison"
