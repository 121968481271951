import { IFormatting, IMarkersType, WidgetItem } from "../../../../models/Widgets";

export const AreaChartGroupedData = (
  currentWidgetData?: WidgetItem,
  areaChartSuggestion?: any
) => {
  const groupBy = currentWidgetData?.groupBy?.at(0);

  const xAxe = currentWidgetData?.xAxe?.length
    ? currentWidgetData?.xAxe?.at(0)
    : areaChartSuggestion.xAxe?.at(0);

  const yAxe = currentWidgetData?.yAxe?.length
    ? currentWidgetData?.yAxe?.at(0)
    : areaChartSuggestion.yAxe?.at(0);

  if (groupBy) {
    return currentWidgetData?.data?.reduce((acc, obj) => {
      const group = obj[groupBy];
      acc[group] = acc[group] || [];
      acc[group].push({
        x: xAxe ? obj[xAxe] : obj.year,
        y: yAxe ? obj[yAxe] : obj.value,
      });
      return acc;
    }, {});
  } else {
    return {
      default: currentWidgetData?.data?.map((l) => ({
        x: xAxe ? l[xAxe] : l.year,
        y: yAxe ? l[yAxe] : l.value,
      })),
    };
  }
};

export const AreaChartDefaultMarkers = (data: any): IMarkersType[] => {

  const groupedData = AreaChartGroupedData(data)

  const markers: IMarkersType[] = Object.keys(groupedData).map((item, index) => ({
    key: item,
    shape: "donut",
  }))
  
  return markers
}

export const getAreaChartDefaultColors = (data: any): IFormatting[] => {

  const groupedData = AreaChartGroupedData(data)

  const colors: IFormatting[] = Object.keys(groupedData).map((item, index) => ({
    key: item,
    color: String(index+1),
  }))
  
  return colors
}