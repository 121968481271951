import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Bell,
  BellFill,
  ChatLeftTextFill,
  Share,
  ArrowLeft,
} from "react-bootstrap-icons";

import {
  HeaderContainer,
  LinkContainer,
  MenuWrapper,
  NavLinkButton,
  NotificationAlert,
  Icon,
  ProjectNameTitle,
  ProjectNameWrapper,
  ProjectTitleAction,
  VerticalSeparator,
  ProjectTitleWrapper,
  Status,
  StatusTitle,
  HamburgerMenu,
  RightWrapper,
  ClockIcon,
  ProjectNameBlock,
  ProjectPopulation,
  ProjectSettingsBlock,
  SubmenuBlock,
  ProjectTitleBlock,
  Header,
  RightBlock,
  LeftBlock,
} from "./styles";
import { Search } from "../SearchBar";
import { Button } from "../Button";
import { Logo } from "../../assets";
import { UserDetails } from "../UserDetails";
import { Submenu } from "../Submenu";
import {
  requestDeleteProject,
  setProjectsSearch,
} from "../../store/slices/projects";
import { getIsPublicMode } from "../../store/selectors/main";
import { getAllPages, getCurrentProjectData } from "../../store/selectors/projects";
import { setActiveModal } from "../../store/slices/modals";
import { useDebounce } from "../../hooks/useDebounce";
import { setIsEditMode } from "../../store/slices/appConfig";
import { EditSwitchComponent } from "../Inputs/EditSwitchComponent/Switch";
import { DotSeparator } from "../ProjectMenu/styles";

export const NavBar = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isEditMode, setProjectEditMode] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const project = useSelector(getCurrentProjectData);
  const isPublicMode = useSelector(getIsPublicMode);
  const pages = useSelector(getAllPages);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const hiddenRoutePattern = /^\/projects\/.+/;
  const currentId = currentPath.split("/projects/")[1]?.split("/")[0];

  const debounceValue = useDebounce(searchInput, 1000);

  const count = pages.items?.reduce((acc, obj) => {
    if (obj.isPublic) {
      return acc + 1;
    }
    return acc;
  }, 0)

  useEffect(() => {
    dispatch(setProjectsSearch(debounceValue));
  }, [debounceValue, dispatch]);

  useEffect(() => {
    setProjectEditMode(true);
    dispatch(setIsEditMode(true));
  }, [currentId, dispatch]);

  const removeProject = () => {
    dispatch(requestDeleteProject({ id: currentId, navigate }));
    navigate("/");
    setMenuOpen(false);
  };

  const onDuplicate = () => { };

  if (location.pathname === "/sign-in") return null;
  if (location.pathname === "/sign-up") return null;
  if (location.pathname === "/forgot-password") return null;

  const onEdit = () => {
    dispatch(
      setActiveModal({ id: "createProjectModal", projectId: currentId })
    );
  };

  const setEditMode = () => {
    setProjectEditMode(!isEditMode);
    dispatch(setIsEditMode(!isEditMode));
  };

  return (
    <Header>
      <HeaderContainer>
        <LeftBlock $isPublic={isPublicMode}>
          {!isPublicMode && (
            <>
              <NavLink
                to={""}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <HamburgerMenu />
              </NavLink>
              <VerticalSeparator $right={-10} />
            </>
          )}
          <NavLink to="/" style={{ display: "flex", alignItems: "center" }}>
            <img width="138" src={Logo} alt="Logo" />
          </NavLink>
          <VerticalSeparator />
          {hiddenRoutePattern.test(currentPath) && (
            <>
              <ProjectNameWrapper>
                {!isPublicMode && (
                  <ArrowLeft
                    fill="#5F6877"
                    cursor={"pointer"}
                    onClick={() => navigate("/projects")}
                  />
                )}
                <ProjectTitleWrapper>
                  <ProjectNameBlock>
                    <ProjectTitleBlock>
                      <ProjectNameTitle>{project?.name}</ProjectNameTitle>
                      {!isPublicMode && isEditMode && (
                        <SubmenuBlock>
                          <ProjectTitleAction
                            onClick={() => {
                              setMenuOpen((prevMenuOpen) => !prevMenuOpen);
                            }}
                          />
                        </SubmenuBlock>
                      )}
                    </ProjectTitleBlock>
                    <ProjectSettingsBlock>
                      <ProjectPopulation>
                        {project?.population}
                      </ProjectPopulation>
                      <DotSeparator />
                      <ProjectPopulation>{project?.measure}</ProjectPopulation>
                    </ProjectSettingsBlock>
                  </ProjectNameBlock>
                  {!isPublicMode && project?.isPublic && (
                    <Status $size={11}>
                      <StatusTitle>Published - {count}</StatusTitle>
                    </Status>
                  )}
                  {isEditMode && menuOpen && (
                    <Submenu
                      projectMenu
                      menuOpen={menuOpen}
                      onEdit={onEdit}
                      setMenuOpen={setMenuOpen}
                      onRemove={removeProject}
                      onDuplicate={onDuplicate}
                    />
                  )}
                </ProjectTitleWrapper>
              </ProjectNameWrapper>
              <RightWrapper>
                {!isPublicMode && (
                  <Icon
                    $showAlert={showAlert2}
                    onClick={() => {
                      setShowAlert2(!showAlert2);
                    }}
                  >
                    {showAlert2 ? <ChatLeftTextFill /> : <ChatLeftTextFill />}
                    <NotificationAlert $active={showAlert2}>8</NotificationAlert>
                  </Icon>
                )}
                <Button
                  onClick={() => {
                    dispatch(setActiveModal({ id: "shareProjectModal" }));
                  }}
                  variant="primary"
                  size="medium"
                  name="Share"
                  icon={<Share />}
                />
                {!isPublicMode && (
                  <EditSwitchComponent
                    onChange={setEditMode}
                    onBlur={() => { }}
                    checked={isEditMode}
                  />
                )}
              </RightWrapper>
            </>
          )}

          {!hiddenRoutePattern.test(currentPath) && (
            <>
              <LinkContainer>
                <NavLinkButton to="/projects">Projects</NavLinkButton>
                <NavLinkButton to="/datasets">Datasets</NavLinkButton>
              </LinkContainer>
            </>
          )}

          <MenuWrapper>
            {!hiddenRoutePattern.test(currentPath) && (
              <Search
                width="medium"
                onChange={(value: string) => setSearchInput(value)}
              />
            )}

            {/* <VerticalSeparator $right={-10} /> */}
            {!isPublicMode && (
              <Icon $showAlert={false} onClick={() => { }} $disableBackground>
                <ClockIcon />
              </Icon>
            )}
          </MenuWrapper>
        </LeftBlock>
        {!isPublicMode && (
          <RightBlock>
            <Icon
              $disableBackground
              $color={"neutralTextWeak"}
              $showAlert={showAlert}
              onClick={() => {
                setShowAlert(!showAlert);
              }}
            >
              {showAlert ? <BellFill /> : <Bell />}
              {showAlert ? <NotificationAlert>8</NotificationAlert> : null}
            </Icon>
            <UserDetails />
          </RightBlock>
        )}
      </HeaderContainer>
    </Header>
  );
};
