import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useMeasure from "react-use-measure";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Group } from "@visx/group";
import { AxisLeft, AxisTop } from "@visx/axis";
import { scaleBand } from "@visx/scale";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { getPageSettings } from "../../../store/selectors/projects";
import { Tooltip } from "../Tooltip";
import { Loader } from "../../Loader";
import { ColorRangeI } from "../../../models/Pages";
import { generateColorRanges } from "./utils/generateColorRanges";
import { getAdditionalKeys } from "./utils/getAdditionalKeys";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import {
  getClientPosition,
  LabelTooltip,
  TickLabel,
} from "../components/LabelTooltip";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { setActiveModal } from "../../../store/slices/modals";
import { createPortal } from "react-dom";
import { Rect } from "./style";
import { getSequentialColorsHex } from "../../../constants/utils/getSequentialsColors";

const rectPadding = 3;

export const MatrixChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
}: {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
}) => {
  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const [ref, bounds] = useMeasure();
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement | null>(null);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const [xAxes, setXAxes] = useState<string[]>([]);
  const [yAxes, setYAxes] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [variations, setVariations] = useState<string[]>([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [tooltip, setTooltip] = useState<{
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);
  const [labelTooltip, setLabelTooltip] = useState<{
    data: string;
    x: number;
    y: number;
  } | null>(null);

  const margin = { top: 20, right: 0, bottom: 10, left: 80 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = scaleBand({
    range: [0, xMax],
    domain: xAxes,
    padding: 0,
    paddingInner: 0,
  });

  const yScale = scaleBand({
    range: [yMax, 0],
    domain: yAxes,
    padding: 0,
    paddingInner: 0,
  });

  const getColor = (value: number) => {
    const range = colorRanges.find(
      (range) => value > range.start && value <= range.end
    );

    if (!value) {
      return colorRanges.at(0)?.color;
    }

    return range ? range.color : undefined;
  };

  useEffect(() => {
    if (
      currentWidget &&
      ["matrixChart", "matrix"].includes(currentWidget.chartType)
    ) {
      if (currentWidget && currentWidget?.data) {
        const dataMatrix = currentWidget?.data;

        const values = dataMatrix?.map((item) => item.value || "0");

        const colorPaletteVariations = getSequentialColorsHex(
          currentWidget.palette?.paletteId
        );

        const xAxe = currentWidget?.xAxe?.[0] || "x";
        const yAxe = currentWidget?.yAxe?.[0] || "y";

        const xAxes =
          dataMatrix?.map((l) =>
            xAxe === "value" ? Number(l?.[xAxe]) : l?.[xAxe]
          ) || [];
        const additionalKeys = getAdditionalKeys(dataMatrix, xAxe, yAxe);
        let additionalKey = additionalKeys[0];
        if (additionalKey === "state") {
          additionalKey = "county";
        }
        const groupBy = currentWidget?.groupBy?.at(0);

        const uniqueValuesKeys =
          (currentWidget?.uniqueValues &&
            Object.keys(currentWidget?.uniqueValues!)) ||
          [];
        const groupByKey =
          groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

        const yAxesNotSorted =
          uniqueValuesKeys?.length && currentWidget?.uniqueValues
            ? currentWidget?.uniqueValues[groupByKey!]
            : dataMatrix?.map((l) => l?.[additionalKeys[0]]) || [];

        const yAxesSorted = yAxesNotSorted?.length
          ? yAxesNotSorted
          : [...new Set(yAxesNotSorted)].sort();

        setXAxes([...new Set(xAxes)].sort());
        setYAxes(yAxesSorted?.slice()?.reverse());
        setXAxe(xAxe);
        setYAxe(additionalKey);
        setValues(values);
        setVariations(colorPaletteVariations?.slice());
        setGroupedData(dataMatrix);
      }
    }
  }, [currentWidget, styleId]);

  const rectWidth = useMemo(() => xScale.bandwidth(), [xScale]);
  const rectHeight = useMemo(
    () => Math.min(yScale.bandwidth() < 16 ? 16 : yScale.bandwidth(), 32),
    [yScale]
  );

  const generateColorRangesCallback = useCallback(
    () =>
      generateColorRanges(
        variations,
        values,
        setColorRanges,
        currentWidget.palette?.range
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variations, values]
  );

  useEffect(() => {
    if (variations?.length) {
      generateColorRangesCallback();
    }
  }, [variations?.length, values, generateColorRangesCallback]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);
  const refHeight = useMemo(
    () => divRef?.current?.parentElement?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, divRef.current]
  );
  const ifRecomendedToMap = currentWidget?.data?.some(
    (d) => d?.county && d?.state
  );
  const recommendedCountAditional = ifRecomendedToMap ? 1 : 0;
  const recommendedCount = AVAILABLE_WIDGETS["matrix"]?.length
    ? AVAILABLE_WIDGETS["matrix"]?.length + 1 + recommendedCountAditional
    : recommendedCountAditional;

  if (_.isEmpty(groupedData)) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }
  const h2 = yAxes?.length * rectHeight - margin.top - 10;
  const svgHeight = h2 < (refHeight || 0) ? refHeight : h2;
  // const paddingTop = yAxes.length > 7 ? 1 : 0;

  return (
    <>
      <HeaderWrapper>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName && <Title>{name}</Title>}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {recommendedCount}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}

        {showLegend ? (
          <ChartLegend legendType="palette" colorRanges={colorRanges} />
        ) : null}
      </HeaderWrapper>
      {yAxes?.length > 7 && (
        <svg height={"25"} width={"100%"}>
          <AxisTop
            scale={xScale}
            top={margin.top}
            left={margin.left}
            hideTicks
            stroke="transparent"
            tickLabelProps={() => ({
              fontSize: 11,
              fill: "#5F6877",
              textAnchor: "middle",
              fontWeight: 400,
            })}
          />
        </svg>
      )}
      <div
        ref={divRef}
        style={
          yAxes?.length > 7
            ? {
                height: refHeight ? refHeight - 16 * 2 : 160,
                overflowY: "auto",
              }
            : { height: "100%", overflowY: "auto" }
        }
      >
        <svg
          width="100%"
          height={yAxes?.length > 7 ? (svgHeight as any) : "100%"}
          ref={ref}
        >
          <Group top={yAxes?.length > 7 ? 0 : margin.top} left={margin.left}>
            {!(yAxes?.length > 7) && (
              <AxisTop
                scale={xScale}
                top={0}
                hideTicks
                stroke="transparent"
                tickLabelProps={() => ({
                  fontSize: 11,
                  fill: "#5F6877",
                  textAnchor: "middle",
                  fontWeight: 400,
                })}
              />
            )}
            <AxisLeft
              scale={yScale}
              hideTicks
              stroke="transparent"
              tickLabelProps={() => ({
                fontSize: 11,
                fill: "#5F6877",
                textAnchor: "start",
                fontWeight: 400,
                dy: 3,
                dx: -78,
              })}
              tickTransform={"translate(0, 3)"}
              numTicks={yScale.domain().length}
              tickComponent={(props) => (
                <TickLabel
                  {...props}
                  setTooltip={setLabelTooltip}
                  length={9}
                  offsetX={-10}
                  offsetY={45}
                />
              )}
            />
            {groupedData.map((d: any, i) => {
              return (
                <React.Fragment key={d.value + i}>
                  <Rect
                    $tooltip={
                      (showTooltip || currentWidget.tooltip) && !recommended
                    }
                    x={xScale(d?.[xAxe || "x"])}
                    y={yScale(d?.[yAxe || "y"])! + rectPadding / 2}
                    width={rectWidth - rectPadding}
                    height={yScale.bandwidth() - rectPadding}
                    fill={getColor(Number(d.value))}
                    rx={2}
                    onMouseMove={(e: any) => {
                      if (
                        (showTooltip || currentWidget.tooltip) &&
                        !recommended
                      ) {
                        const { x, y } = getClientPosition(e);

                        setTooltip({
                          data: {
                            [xAxe as string]: d?.[xAxe || "x"],
                            [yAxe as string]: d?.[yAxe || "y"],
                            value: d.value,
                          },
                          x: x - 27,
                          y: y - 85,
                        });
                      }
                    }}
                    onMouseLeave={() => setTooltip(null)}
                  />
                </React.Fragment>
              );
            })}
            {/* {xAxes?.map((x, i) => {
              const minusRectPadding = rectPadding / 2;
              const length = yAxes.length;
              return (
                <line
                  key={"lineX" + i}
                  x1={i * rectWidth - minusRectPadding}
                  y1={0}
                  x2={i * rectWidth - minusRectPadding}
                  y2={yScale.bandwidth() * length + paddingTop}
                  stroke="#949ba7"
                  strokeWidth={1}
                  strokeDasharray="2,2"
                />
              );
            })}
            {yAxes?.map((x, i) => {
              const minusRectPadding = rectPadding / 2;

              return (
                <line
                  key={"lineY" + i}
                  x1={0}
                  y1={
                    yScale(x)!
                  }
                  x2={rectWidth * xAxes.length - minusRectPadding}
                  y2={
                    yScale(x)!
                  }
                  stroke="#949ba7"
                  strokeWidth={1}
                  strokeDasharray="2,2"
                />
              );
            })} */}
          </Group>
        </svg>
      </div>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            matrix
          />,
          document.body
        )}
      {labelTooltip && (
        <LabelTooltip
          x={labelTooltip?.x}
          y={labelTooltip?.y}
          data={labelTooltip?.data}
        />
      )}
    </>
  );
};
