import { StoreInterface } from "../../models";

export const getTheme = (store: StoreInterface) =>
  store.appConfig.selectedTheme;

export const getUser = (store: StoreInterface) => store.auth.user;

export const getIsPublicMode = (store: StoreInterface) =>
  store.appConfig.isPublicRoute;

export const getIsEditMode = (store: StoreInterface) =>
  store.appConfig.isEditMode;

export const getReportActionType = (store: StoreInterface) =>
  store.appConfig.reportActionType;

export const getIsPageTypeSelect = (store: StoreInterface) =>
  store.appConfig.isPageTypeSelect;

export const getPageIdSelected = (store: StoreInterface) =>
  store.appConfig.pageIdSelected;

export const getComparasionActionType = (store: StoreInterface) =>
  store.appConfig.comparisonActionType;