import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ThreeDots } from "react-bootstrap-icons";
import { ClockHistory } from "react-bootstrap-icons";
import { List } from "react-bootstrap-icons";

export const HeaderContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => theme.neutralBackgroundMedium};
  justify-content: space-between;
  z-index: 1000;
`;

export const Header = styled.div`
  height: 64px;
`;

export const VerticalSeparator = styled.div<{
  $right?: number;
  $left?: number;
}>`
  border-left: 1px solid ${({ theme }) => theme.neutralBorder};
  height: 64px;
  position: relative;
  align-self: center;
  padding-left: 16px;
  ${({ $right }) => ($right ? "right:" + $right + "px" : "")}
  ${({ $left }) => ($left ? "left:" + $left + "px" : "")}
`;

export const ProjectTitleBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProjectNameBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubmenuBlock = styled.div`
  margin-top: 5px;
  margin-left: 10px;
`;

export const ProjectSettingsBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProjectTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  padding-left: 20px;
  text-align: center;
`;

export const NavLinkButton = styled(NavLink)`
  display: inline-grid;
  height: 40px;
  width: 104px;
  justify-items: center;
  align-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.neutralWeak};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &.active {
    display: flex;
    height: 40px;
    width: 104px;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
    color: ${({ theme }) => theme.primaryText};
    border-radius: 5px;

    &:after {
      content: "";
      height: 2px;
      border-radius: 5px;
      width: 100px;
      margin-top: 62px;
      background: ${({ theme }) => theme.primaryBorder};
      position: absolute;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
    border-radius: 5px;
    color: ${({ theme }) => theme.neutralText};
  }
`;

export const ProjectNameTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const ProjectPopulation = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const ProjectTitleAction = styled(ThreeDots)`
  align-self: center;
  cursor: pointer;
  filter: invert(39%) sepia(9%) saturate(571%) hue-rotate(178deg)
    brightness(94%) contrast(89%);
`;

export const ClockIcon = styled(ClockHistory)`
  width: 16px;
  fill: #5f6877;
  height: 16px;
`;

export const HamburgerMenu = styled(List)`
  width: 20px;
  height: 20px;
  fill: #5f6877;
`;

export const RightWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const Icon = styled.button<{
  $disableBackground?: boolean;
  $color?: string;
  $showAlert: boolean;
}>`
  display: flex;
  align-self: center;
  border: none;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  transition: 0.3s linear;
  background: ${({ theme, $disableBackground, $showAlert }) =>
    $disableBackground
      ? "transparent"
      : $showAlert
        ? theme.primaryBackgroundActive
        : theme.primaryBackground};
  color: ${({ theme, $color }) => ($color ? theme[$color] : theme.primaryText)};
  justify-content: center;
  place-items: center;
  cursor: pointer;
  position: relative;
  margin-right: ${({ $color }) => ($color ? "15px" : "")};

  svg {
    width: 14px;
    height: 16px;
    position: absolute;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const NotificationAlert = styled.div<{ $active?: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background: ${({ theme }) => theme.primaryAction};
  right: -5px;
  bottom: -5px;
  position: absolute;
  padding: 1px 3px;
  border-radius: 4px;
  border: 1px solid #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 14px; */
  color: ${({ theme }) => theme.primaryTextStrong};
  transition: 0.3s linear;
  ${({ $active }) => ($active ? `opacity: 1` : `opacity: 0`)}
`;

export const HeadingMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProjectNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
`;

export const Status = styled.div<{ $size: number }>`
  font-size: ${({ $size }) => `${$size}px `};
  color: ${({ theme }) => theme.successText};
  border: 1px solid ${({ theme }) => theme.successBackgroundMedium};
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.successBackground};
  align-self: center;
  margin: 0 4px;
  border-radius: 2px;
  font-weight: 400;
`;

export const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  font-size: 12px;
  height: 20px;
`;

export const RightBlock = styled.div`
  display: flex;
  border-left: ${({ theme }) => `1px solid ${theme.successBackground}`};
  padding: 20px 20px 20px 0;
`;

export const LeftBlock = styled.div<{ $isPublic?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px ${({ $isPublic }) => $isPublic ? '20px' : '12px'} 20px 20px;
`;
