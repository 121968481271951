import L from "leaflet";
import UsCounties from "./GeoJson/us-counties.json";
import UsStates from "./GeoJson/us-states.json";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import { mapPopup } from "./popup";
import { MarkersVisualisationDataDto } from "../../../../models/Widgets";
import { ColorRangeI } from "../../../../models/Pages";

export const mapLayers = ({
  geoJSONLayersRef,
  mapRef,
  mapType,
  colorRanges,
  data,
  showTooltip,
  widgetTooltip,
  widgetPreview
}: {
  geoJSONLayersRef: any;
  mapRef: any;
  mapType: any;
  colorRanges: ColorRangeI[];
  data?: MarkersVisualisationDataDto[];
  showTooltip?: boolean;
  widgetTooltip?: boolean;
  widgetPreview?: boolean;
}) => {
  geoJSONLayersRef.current.forEach((layer: any) => {
    mapRef.current.removeLayer(layer);
  });
  geoJSONLayersRef.current = [];

  const existState = data?.some((d) => !!d.state);

  const highlightFeature = (feature: any, e: any) => {
    const layer = e.target;

    const countyState = feature.properties.COUNTY_STATE_CODE?.split(",")?.at(0);
    const county = data?.find((c) => c.county === countyState);
    let color = colorRanges?.at(0)?.color;
    const lastItem = colorRanges[colorRanges?.length - 1].start;
    const countyValue = parseInt(county?.value || "0");

    if (countyValue === 0) {
      color = colorRanges[0].color;
    } else if (countyValue > lastItem) {
      color = colorRanges[colorRanges?.length - 1].color;
    } else {
      const localColor = colorRanges.find((c: ColorRangeI, i: number) => {
        const nextItem =
          i === colorRanges?.length - 1 ? colorRanges?.length - 1 : i + 1;
        return (
          countyValue &&
          countyValue >= c.start &&
          countyValue < colorRanges[nextItem].start
        );
      });
      color = localColor ? localColor?.color : colorRanges[0].color;
    }

    color !== "#E0DDDC" &&
    (countyValue !== 0 || 
    county?.state === "California") &&
      layer.setStyle({
        weight: 2,
        color: "black",
        dashArray: "",
      });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  };

  const resetHighlight = (e: any) => {
    geoJSONLayer.resetStyle(e.target);
  };

  const onEachFeature = (feature: any, layer: any) => {
    const countyState = feature.properties.COUNTY_STATE_CODE?.split(",")?.at(0);
    const county = data?.find((c) => c.county === countyState);
    const countyValue = parseInt(county?.value || "0");

    if (!widgetPreview && (countyValue !== 0 || county?.state === "California")) {
      mapPopup({
        properties: {
          countyStateCode: feature.properties.COUNTY_STATE_CODE,
          members: county?.value,
          year: county?.year,
        },
        layer,
      });
    }

    layer.on({
      mouseover: (e: any) => widgetPreview? null : highlightFeature(feature, e),
      mouseout: resetHighlight,
    });
  };

  const geoJSONLayer = L.geoJSON(
    mapType === "counties" ? UsCounties : UsStates,
    {
      style: function (feature: any) {
        const countyState = feature.properties.COUNTY_STATE_NAME?.split(",")
          ?.at(0)
          ?.trim();
        const state = feature.properties.COUNTY_STATE_NAME?.split(",")
          ?.at(1)
          ?.trim();

        let county = data?.find((c) => c.county === countyState);

        if (existState) {
          county = data?.find(
            (c) => c.county === countyState && c.state === state
          );
        }
        let color = colorRanges?.at(0)?.color;
        const lastItem = colorRanges[colorRanges?.length - 1].start;
        const countyValue = parseInt(county?.value || "0");
        if (countyValue === 0) {
          color = colorRanges[0].color;
        } else if (countyValue > lastItem) {
          color = colorRanges[colorRanges?.length - 1].color;
        } else {
          const localColor = colorRanges.find((c: ColorRangeI, i: number) => {
            const nextItem =
              i === colorRanges?.length - 1 ? colorRanges?.length - 1 : i + 1;
            return (
              countyValue &&
              countyValue >= c.start &&
              countyValue < colorRanges[nextItem].start
            );
          });
          color = localColor ? localColor?.color : colorRanges[0].color;
        }

        return {
          color: "white",
          stroke: "white",
          strokeWidth: "0px",
          fill: true,
          fillColor:
            countyValue === 0 && state !== "California"
              ? "rgba(72, 70, 91, 0.15)"
              : hexToRGBA(color, 1),
          weight: 1,
          fillOpacity: 1,
        };
      },
      onEachFeature: showTooltip || widgetTooltip ? onEachFeature : () => {},
    }
  );

  geoJSONLayer.addTo(mapRef.current);

  geoJSONLayersRef.current.push(geoJSONLayer);
};
