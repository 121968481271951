import { useEffect, useState } from "react";
import { Trash, CheckCircle } from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";

import { Button } from "../../Button";
import { ModalHeadingNameWrapper } from "../DatasetsOverview/styles";
import { DatavizRecommendedWidgets } from "../../DatavizRecommendedWidgets";

import {
  ModalFooterWrapper,
  ModalHeading,
  ModalOverlay,
  ModalWrapper,
  WrapperButtons,
} from "../styles";
import {
  CloseModal,
  ModalHeadingWrapperExt,
  ModalSubmenu,
  OverviewContentExt,
  SubmenuItem,
  SubmenuTitle,
} from "./styles";
import { MapSettings } from "../../MapSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  requestPageWidgets,
  requestUpdatePage,
  setCurrentWidget,
  setDraftPageSettings,
  setIsLoading,
  setPageSettings,
  setPageWidgets,
} from "../../../store/slices/projectPages";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getCurrentWidget,
  getDraftPageSettings,
  getLayout,
  getPageSettings,
} from "../../../store/selectors/projects";
import {
  requestCreateWidgets,
  requestDeleteWidget,
  requestUpdateWidget,
  requestUpdateWidgets,
  requestWidgetsSuggestions,
} from "../../../store/slices/widgets";
import {
  CreateAreaChartWidgetDto,
  CreateBarWidgetDto,
  CreateLineChartWidgetDto,
  CreateLollipopChartWidgetDto,
  CreateMapWidgetDto,
  CreateMatrixChartWidgetDto,
  CreateSankeyChartWidgetDto,
  MarkersVisualisationDataDto,
  UpdateWidgetDTO,
  WidgetItem,
} from "../../../models/Widgets";
import { LAYOUTS } from "../../ProjectPageLayouts/config";
import { findFirstCommonElement } from "../../../helpers/firstCommonElement";
import { adjustArray } from "../../../helpers/ajustArray";
import { mockWidgetItem } from "./data/mock";
import _ from "lodash";
import { useDebounceCallback } from "usehooks-ts";
import { extractBlockIds, LayoutI } from "../../../helpers/extractBlockIds";
import { getModalCreateOptions } from "../../../store/selectors/modals";
import { defaultHeader } from "../../AddStorytellingSection";
import { PageSettingsDTO } from "../../../models/Pages";

type ModalProps = {
  closeModal: () => void;
};

export const DataVizSettings = ({ closeModal }: ModalProps) => {
  const [searchParams] = useSearchParams();
  const [isClosing, setIsClosing] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState("Recommended Widgets");
  const currentWidget = useSelector(getCurrentWidget);
  const aiKpi = useSelector(getCurrentPageKpi);

  const pageSettings = useSelector(getPageSettings);
  const createNewProject = useSelector(getModalCreateOptions);
  const pageDraftSettings = useSelector(getDraftPageSettings);
  const settings = createNewProject ? pageDraftSettings : pageSettings;

  const [widgetData, setWidgetData] = useState<WidgetItem>(
    currentWidget || mockWidgetItem
  );

  const widget = useSelector(getCurrentWidget);
  const currentPage = useSelector(getPageSettings);
  const widgets = useSelector(getCurrentPageWidgets);
  const currentLayout = useSelector(getLayout);

  const [selectedChart, setSelectedChart] = useState<string | undefined>();

  const searchFullTrue = (obj: any) => {
    if (obj === null || typeof obj !== "object") {
      return false;
    }

    if (obj.full === true) {
      return true;
    }

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (searchFullTrue(obj[key])) {
          return true;
        }
      }
    }

    return false;
  };

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(setCurrentWidget(undefined));
      closeModal();
    }, 1000);
  };

  const template = `${currentLayout[0]}_${widgets.items.length}_`;

  const layouts = LAYOUTS.filter((item) => item.id.includes(template));

  const fullScreenLayout =
    layouts.find((item) => searchFullTrue(item.arranging))?.id || "";

  const getAllWidgets = () => {
    const query = Array.from(searchParams?.entries())?.find((param: any) => param[0] === 'query')?.[1]
    dispatch(
      requestPageWidgets({
        pageId: currentPage.id!,
        query: query || '',
        includeData: true,
      })
    );
  };
  const debouncedGetAll = useDebounceCallback(getAllWidgets, 800);

  const handleSettingsUpdate = (newSettings: PageSettingsDTO) => {
    const newPage = { ...newSettings, header: defaultHeader };
    dispatch(
      createNewProject
        ? setDraftPageSettings(newPage)
        : setPageSettings(newPage)
    );
    if (
      newSettings.templateId &&
      newSettings.templateId !== settings.templateId &&
      !createNewProject
    ) {
      const layout = LAYOUTS.find((l) => l.id === newSettings.templateId);

      let blocks: any = extractBlockIds(layout?.arranging as LayoutI);
      let restWidgets = [...widgets?.items];
      let mapedWidgets = [...widgets?.items];
      if (blocks.length > restWidgets?.length) {
        blocks = adjustArray(blocks, restWidgets?.length);
      }
      let chartTypes = restWidgets.map((r: any) => r.chartType);

      for (let i = 0; i < blocks?.length; i++) {
        const block = blocks[i];
        const chartType = findFirstCommonElement(chartTypes, block.widgets);
        const index = chartTypes.findIndex((c: string) => c === chartType);
        if (index !== -1) {
          chartTypes.splice(index, 1);
        }
        const chart = restWidgets?.find(
          (widget: any) => widget.chartType === chartType
        );
        if (chart) {
          const indexSuggestion = restWidgets?.findIndex(
            (widget: any) => widget.chartType === chartType
          );
          if (indexSuggestion !== -1) {
            restWidgets?.splice(indexSuggestion, 1);
          }
          mapedWidgets = mapedWidgets?.map((w) => {
            if (w.id === chart.id) {
              return { ...w, blockId: block?.blockId?.toString() };
            }
            return w;
          });
        }
      }
      mapedWidgets.sort((a, b) => parseInt(a.blockId!) - parseInt(b.blockId!));
      dispatch(setPageWidgets({ count: widgets.count, items: mapedWidgets }));
    }
  };

  useEffect(() => {
    if (
      currentWidget?.chartType === "mapChart" &&
      currentWidget?.layout === "fullScreen" &&
      currentLayout !== fullScreenLayout
    ) {
      dispatch(
        requestUpdateWidget({
          ...widgetData,
          layout: "minimalist",
          apparitionConfig: widgetData.apparitionConfig || {},
          blockId: String(widget?.blockId),
        })
      );
      setWidgetData({ ...widgetData, layout: "minimalist" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateWidget = () => {
    try {
      if (
        widgetData.chartType === "mapChart" &&
        widgetData.layout === "fullScreen" &&
        currentLayout !== fullScreenLayout &&
        !!fullScreenLayout
      ) {
        handleSettingsUpdate({ ...settings, templateId: fullScreenLayout });
      }

      dispatch(
        requestUpdateWidget({
          ...widgetData,
          apparitionConfig: widgetData.apparitionConfig || {},
          blockId: String(widget?.blockId),
        })
      );
      debouncedGetAll();
    } catch (e) {
      console.error(e);
    } finally {
      handleOnClose();
    }
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  const removeWidget = () => {
    if (widget?.id) {
      if (currentPage.dashType === "storytelling") {
        dispatch(setIsLoading(true));
        dispatch(requestDeleteWidget(widget?.id));
        setTimeout(() => {
          dispatch(
            requestPageWidgets({
              pageId: String(currentPage?.id),
              includeData: true,
            })
          );
        }, 1000);
        dispatch(
          requestWidgetsSuggestions({
            pageId: widget?.pageId,
            projectId: currentPage.projectId,
            includeData: false,
            callbacks: {
              onSuccess: () => {
                setTimeout(() => {
                  dispatch(setIsLoading(false));
                }, 500);
              },
            },
          })
        );
        handleOnClose();
        return;
      }
      const layout = LAYOUTS.find((l) => l.id === currentPage.templateId);
      if (layout) {
        const restWidgets = [
          ...widgets?.items?.filter((w) => w.id !== widget.id),
        ];

        let numComplexWidgets = 0;
        if (restWidgets.length) {
          numComplexWidgets = restWidgets.filter((w) =>
            ["mapChart", "sankey", "sankeyChart"].includes(w.chartType)
          ).length;
        }

        let newLayout = LAYOUTS.find(
          (l) =>
            (l.variant === layout.variant ||
              ["c", "b", "a"].includes(l.variant)) &&
            l.numWidgets === restWidgets.length + (aiKpi?.count > 0 ? 1 : 0) &&
            l.numComplexWidgets === numComplexWidgets
        );

        if (!newLayout) {
          newLayout = LAYOUTS.find(
            (l) =>
              l.numWidgets === 8 &&
              l.numComplexWidgets === numComplexWidgets &&
              l.variant === "c"
          );
        }
        const emptyBlockIds = new Set();
        let blocks: any = extractBlockIds(layout?.arranging as LayoutI);
        if (blocks.length > restWidgets?.length) {
          blocks = adjustArray(blocks, restWidgets?.length);
        }
        let chartTypes = restWidgets.map((r: any) => r.chartType);

        let barChartRequestData: UpdateWidgetDTO[] = [];
        let lineChartRequestData: UpdateWidgetDTO[] = [];
        let lollipopChartRequestData: UpdateWidgetDTO[] = [];
        let sankeyChartRequestData: UpdateWidgetDTO[] = [];
        let mapChartRequestData: UpdateWidgetDTO[] = [];
        let areaChartRequestData: UpdateWidgetDTO[] = [];
        let matrixChartRequestData: UpdateWidgetDTO[] = [];
        let polarAreaChartRequestData: UpdateWidgetDTO[] = [];
        let punchcardChartRequestData: UpdateWidgetDTO[] = [];
        let radarChartRequestData: UpdateWidgetDTO[] = [];
        let scatterplotChartRequestData: UpdateWidgetDTO[] = [];
        let bubbleChartRequestData: UpdateWidgetDTO[] = [];

        const populateBlocks = (
          blocks: any = [],
          isUsedAdmisibleWidgets: boolean = false
        ) => {
          for (let i = 0; i < blocks?.length; i++) {
            const block = blocks[i];
            const avaibleBlockWidgets = !isUsedAdmisibleWidgets
              ? block.widgets
              : newLayout?.admisibleWidgets;
            const chartType = findFirstCommonElement(
              chartTypes,
              avaibleBlockWidgets
            );

            const index = chartTypes.findIndex((c: string) => c === chartType);
            if (index !== -1) {
              chartTypes.splice(index, 1);
            }
            const chart = restWidgets?.find(
              (widget: any) => widget.chartType === chartType
            );

            if (chart) {
              const indexSuggestion = restWidgets?.findIndex(
                (widget: any) => widget.chartType === chartType
              );
              if (indexSuggestion !== -1) {
                restWidgets?.splice(indexSuggestion, 1);
              }

              const blockId =
                aiKpi?.count > 0 ? block?.blockId + 1 : block?.blockId;
              switch (chart?.chartType) {
                case "mapChart":
                  mapChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "areaChart":
                  areaChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "lineChart":
                  lineChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "barChart":
                  barChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "lollipopChart":
                  lollipopChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "sankey":
                  sankeyChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "matrix":
                  matrixChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "scatterplot":
                  scatterplotChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "radar":
                  radarChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "punchcardChart":
                  punchcardChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "polarAreaChart":
                  polarAreaChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                case "bubbleChart":
                  bubbleChartRequestData.push({
                    id: chart.id,
                    blockId: blockId?.toString(),
                  });
                  break;
                default:
              }
            } else {
              emptyBlockIds.add(block?.blockId);
            }
          }
        };

        populateBlocks(blocks);

        if (emptyBlockIds?.size) {
          const emptyBlocks = blocks.filter((item: any) =>
            emptyBlockIds.has(item.blockId)
          );

          populateBlocks(emptyBlocks, true);
        }

        const existCharts = [
          mapChartRequestData,
          barChartRequestData,
          lineChartRequestData,
          lollipopChartRequestData,
          sankeyChartRequestData,
          areaChartRequestData,
          matrixChartRequestData,
          radarChartRequestData,
          scatterplotChartRequestData,
          punchcardChartRequestData,
          polarAreaChartRequestData,
          bubbleChartRequestData,
        ].some((data) => data.length > 0);

        if (existCharts) {
          dispatch(setIsLoading(true));
          dispatch(requestDeleteWidget(widget?.id));
          dispatch(
            requestUpdatePage({ ...currentPage, templateId: newLayout?.id! })
          );

          const query = Array.from(searchParams?.entries())?.find((param: any) => param[0] === 'query')?.[1]

          dispatch(
            requestUpdateWidgets({
              barChart: barChartRequestData,
              lineChart: lineChartRequestData,
              lollipopChart: lollipopChartRequestData,
              sankeyChart: sankeyChartRequestData,
              areaChart: areaChartRequestData,
              matrixChart: matrixChartRequestData,
              mapChart: mapChartRequestData,
              scatterplotChart: scatterplotChartRequestData,
              polarAreaChart: polarAreaChartRequestData,
              punchcardChart: punchcardChartRequestData,
              radarChart: radarChartRequestData,
              bubbleChart: bubbleChartRequestData,
              pageId: widget?.pageId,
              query: query || '',
            })
          );
          dispatch(setCurrentWidget(undefined));
          dispatch(
            requestWidgetsSuggestions({
              pageId: widget?.pageId,
              projectId: currentPage.projectId,
              includeData: false,
              callbacks: {
                onSuccess: () => {
                  setTimeout(() => {
                    dispatch(setIsLoading(false));
                  }, 500);
                },
              },
            })
          );
          handleOnClose();
        }
      }
    }
  };

  const changeWidget = () => {
    if (selectedChart !== widget?.id) {
      let barChartRequestData: CreateBarWidgetDto[] = [];
      let lineChartRequestData: CreateLineChartWidgetDto[] = [];
      let lollipopChartRequestData: CreateLollipopChartWidgetDto[] = [];
      let sankeyChartRequestData: CreateSankeyChartWidgetDto[] = [];
      let areaChartRequestData: CreateAreaChartWidgetDto[] = [];
      let matrixChartRequestData: CreateMatrixChartWidgetDto[] = [];
      let mapChartRequestData: CreateMapWidgetDto[] = [];
      const pageId = widget?.pageId;

      switch (selectedChart) {
        case "matrix":
          const groupBy = currentWidget?.arrangeBy?.at(0)!;
          const arrangeBy = Object.keys(currentWidget?.uniqueValues!)?.at(1)!;
          const display = currentWidget?.display?.at(0)!;
          matrixChartRequestData.push({
            ...widget,
            chartType: "matrixChart",
            blockId: widget?.blockId?.toString(),
            dataFormat: widget?.dataFormat || {},
            palette: widget?.palette || {},
            groupBy: [groupBy],
            xAxe: [arrangeBy],
            yAxe: [display],
            uniqueValues: currentWidget?.uniqueValues || {},
          } as CreateMatrixChartWidgetDto);
          break;
        case "areaChart":
          areaChartRequestData.push({
            ...widget,
            chartType: "areaChart",
            blockId: widget?.blockId?.toString(),
            formatting: widget?.formatting ?? [{}],
            markers: currentWidget?.markers ??
              widget?.markers ?? [{ key: "default", shape: "donut" }],
          } as CreateAreaChartWidgetDto);
          break;
        case "lineChart":
          lineChartRequestData.push({
            ...widget,
            chartType: "lineChart",
            blockId: widget?.blockId?.toString(),
            formatting: widget?.formatting ?? [{}],
            markers: currentWidget?.markers ??
              widget?.markers ?? [{ key: "default", shape: "donut" }],
          } as CreateLineChartWidgetDto);
          break;
        case "barChart_horizontal":
          barChartRequestData.push({
            ...widget,
            chartType: "barChart",
            blockId: widget?.blockId?.toString(),
            orientation: "horizontal",
            formatting: currentWidget?.markers ?? widget?.formatting ?? [{}],
          } as CreateBarWidgetDto);
          break;
        case "barChart_vertical":
          barChartRequestData.push({
            ...widget,
            chartType: "barChart",
            blockId: widget?.blockId?.toString(),
            orientation: "vertical",
            formatting: currentWidget?.markers ?? widget?.formatting ?? [{}],
          } as CreateBarWidgetDto);
          break;
        case "lollipopChart_vertical":
          lollipopChartRequestData.push({
            ...widget,
            chartType: "lollipopChart",
            blockId: widget?.blockId?.toString(),
            orientation: "vertical",
            markers: currentWidget?.markers ??
              widget?.markers ?? [{ key: "default", shape: "rhombus" }],
            formatting: widget?.formatting ?? [{}],
            uniqueValues: widget?.uniqueValues ?? {},
          } as CreateLollipopChartWidgetDto);
          break;
        case "lollipopChart_horizontal":
          lollipopChartRequestData.push({
            ...widget,
            chartType: "lollipopChart",
            blockId: widget?.blockId?.toString(),
            orientation: "horizontal",
            markers: currentWidget?.markers ??
              widget?.markers ?? [{ key: "default", shape: "rhombus" }],
            formatting: widget?.formatting ?? [{}],
            uniqueValues: widget?.uniqueValues ?? {},
          } as CreateLollipopChartWidgetDto);
          break;
        case "sankey_map":
          const sankeyMapChartData =
            currentWidget?.layers?.at(0)?.data || currentWidget?.data || [];
          const data = sankeyMapChartData?.reduce(
            (
              t: MarkersVisualisationDataDto[],
              r: MarkersVisualisationDataDto
            ) => {
              const exist = t?.some(
                (n: MarkersVisualisationDataDto) => n.year === r.year
              );
              if (exist) {
                return t?.map((d: MarkersVisualisationDataDto) => {
                  if (d.year === r.year) {
                    return {
                      ...d,
                      value: (Number(d.value) + Number(r.value)).toString(),
                    };
                  }
                  return d;
                });
              }
              return [...t, r];
            },
            []
          );
          sankeyChartRequestData.push({
            ...widget,
            palette: widget?.palette || {},
            chartType: "sankeyChart",
            blockId: widget?.blockId?.toString(),
            display: ["value"],
            arrangeBy: ["state", "year"],
            data,
            query: widget?.layers?.at(0)?.query || currentWidget?.query,
          } as unknown as CreateSankeyChartWidgetDto);
          break;
        case "sankey":
          let groupByS = currentWidget?.groupBy?.at(0)!;
          const xAxe = currentWidget?.xAxe?.at(0)!;
          const yAxe = currentWidget?.yAxe?.at(0)!;
          if (!groupByS) {
            const dataKeys = Object.keys(widget?.data?.at(0));
            const notUseKey = dataKeys?.filter(
              (r) => ![yAxe, xAxe].includes(r)
            );
            groupByS = notUseKey?.at(0)!;
          }
          sankeyChartRequestData.push({
            ...widget,
            palette: widget?.palette || {},
            chartType: "sankeyChart",
            blockId: widget?.blockId?.toString(),
            display: [yAxe],
            arrangeBy: [groupByS, xAxe],
          } as unknown as CreateSankeyChartWidgetDto);
          break;
        case "matrix_map":
          const matrixMapChartData =
            currentWidget?.layers?.at(0)?.data || currentWidget?.data || [];
          const dataMatrix = matrixMapChartData?.reduce(
            (
              t: MarkersVisualisationDataDto[],
              r: MarkersVisualisationDataDto
            ) => {
              if (r.state === "California") {
                const exist = t?.some(
                  (n: MarkersVisualisationDataDto) =>
                    n.year === r.year && n.county === r.county
                );
                if (exist) {
                  return t?.map((d: MarkersVisualisationDataDto) => {
                    if (d.year === r.year && d.county === r.county) {
                      return {
                        ...d,
                        value: (Number(d.value) + Number(r.value)).toString(),
                      };
                    }
                    return d;
                  });
                }
                return [
                  ...t,
                  { year: r.year, value: r.value, county: r.county },
                ];
              }
              return t;
            },
            []
          );
          const uniqueValues = dataMatrix?.reduce(
            (t: string[], r: MarkersVisualisationDataDto) => {
              if (!t?.includes(r.county)) {
                return [...t, r.county];
              }
              return t;
            },
            []
          );
          matrixChartRequestData.push({
            ...widget,
            data: dataMatrix,
            layers: undefined,
            chartType: "matrixChart",
            xAxe: ["year"],
            yAxe: ["value"],
            groupBy: ["county"],
            uniqueValues: uniqueValues ? { county: uniqueValues } : [],
            legend: widget?.legend ?? false,
            tooltip: widget?.tooltip ?? false,
            blockId: widget?.blockId?.toString(),
            dataFormat: widget?.dataFormat || {},
            palette: (widget?.palette as any) || {},
            query: widget?.layers?.at(0)?.query || currentWidget?.query,
          } as CreateMatrixChartWidgetDto);
          break;
        case "map_matrix":
          mapChartRequestData.push({
            ...widget,
            blockId: widget?.blockId?.toString(),
            layers: [
              {
                data: currentWidget?.data,
                name: "Members by Year",
                query: currentWidget?.query!,
                colour: "green",
                format: "geojson",
                tooltip: true,
                analytics: "average",
                datasetId: currentWidget?.datasetId!,
                geospatialData: ["state", "county"],
                arrangeByMetric: ["value"],
                visualisationType: "markers",
              },
            ],
            uniqueValues: [],
            dataFormat: widget?.dataFormat || {},
            chartType: "mapChart",
            layout: "card",
            legend: false,
            background: "white",
            search: false,
          } as CreateMapWidgetDto);
          break;
        default:
      }
      const existCharts =
        barChartRequestData?.length +
        lineChartRequestData?.length +
        lollipopChartRequestData?.length +
        sankeyChartRequestData?.length +
        areaChartRequestData?.length +
        matrixChartRequestData?.length +
        mapChartRequestData?.length;

      if (existCharts > 0 && pageId) {
        setLoader(true);

        const query = Array.from(searchParams?.entries())?.find((param: any) => param[0] === 'query')?.[1]
        
        dispatch(setIsLoading(true));
        dispatch(requestDeleteWidget(widget?.id));
        dispatch(
          requestCreateWidgets({
            barChart: barChartRequestData,
            lineChart: lineChartRequestData,
            lollipopChart: lollipopChartRequestData,
            sankeyChart: sankeyChartRequestData,
            areaChart: areaChartRequestData,
            matrixChart: matrixChartRequestData,
            mapChart: mapChartRequestData,
            radarChart: [],
            scatterplotChart: [],
            polarAreaChart: [],
            punchcardChart: [],
            bubbleChart: [],
            pageId,
            query: query || '',
          })
        );
        if (["sankey_map", "matrix_map"].includes(selectedChart!)) {
          const currentTemplate = LAYOUTS.find(
            (r) => r.id === currentPage.templateId
          );
          const isFullMap = searchFullTrue(currentTemplate?.arranging);

          if (isFullMap) {
            let newLayout = LAYOUTS.find(
              (l) =>
                l.numWidgets === currentTemplate?.numWidgets &&
                l.numComplexWidgets === currentTemplate.numComplexWidgets &&
                l.id !== currentTemplate.id
            );
            dispatch(
              requestUpdatePage({ ...currentPage, templateId: newLayout?.id! })
            );
          }
        }
        handleOnClose();
      }
    }
  };

  return (
    <ModalOverlay
      $centred
      $isClosing={isClosing}
      onClick={handleOnClose}
      $noBlur
    >
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $recommendedWidgetsStyles
      >
        <ModalHeadingWrapperExt>
          <ModalHeadingNameWrapper>
            <ModalHeading>{widget?.name}</ModalHeading>
            <CloseModal onClick={handleOnClose} />
          </ModalHeadingNameWrapper>
          <ModalSubmenu>
            <SubmenuItem
              $selected={selectedItem === "Recommended Widgets"}
              onClick={() => handleItemClick("Recommended Widgets")}
            >
              <SubmenuTitle>Recommended Widgets</SubmenuTitle>
            </SubmenuItem>
            <SubmenuItem
              $selected={selectedItem === "Configure Widget"}
              onClick={() => handleItemClick("Configure Widget")}
            >
              <SubmenuTitle>Configure Widget</SubmenuTitle>
            </SubmenuItem>
          </ModalSubmenu>
        </ModalHeadingWrapperExt>

        {!loader ? (
          <ModalOverlay
            $centred
            $isClosing={isClosing}
            onClick={handleOnClose}
            $noBlur
          >
            <ModalWrapper
              $isClosing={isClosing}
              onClick={(e) => e.stopPropagation()}
              $recommendedWidgetsStyles
            >
              <ModalHeadingWrapperExt>
                <ModalHeadingNameWrapper>
                  <ModalHeading>{widget?.name}</ModalHeading>
                  <CloseModal onClick={handleOnClose} />
                </ModalHeadingNameWrapper>
                <ModalSubmenu>
                  <SubmenuItem
                    $selected={selectedItem === "Recommended Widgets"}
                    onClick={() => handleItemClick("Recommended Widgets")}
                  >
                    <SubmenuTitle>Recommended Widgets</SubmenuTitle>
                  </SubmenuItem>
                  <SubmenuItem
                    $selected={selectedItem === "Configure Widget"}
                    onClick={() => handleItemClick("Configure Widget")}
                  >
                    <SubmenuTitle>Configure Widget</SubmenuTitle>
                  </SubmenuItem>
                </ModalSubmenu>
              </ModalHeadingWrapperExt>

              <OverviewContentExt>
                {selectedItem === "Recommended Widgets" ? (
                  <DatavizRecommendedWidgets
                    setSelectedChart={setSelectedChart}
                  />
                ) : (
                  <MapSettings
                    widgetData={widgetData}
                    setWidgetData={setWidgetData}
                    fullScreenLayout={!!fullScreenLayout}
                  />
                )}
              </OverviewContentExt>

              <ModalFooterWrapper>
                {selectedItem === "Recommended Widgets" ? (
                  <>
                    <Button
                      name="Cancel"
                      onClick={handleOnClose}
                      variant="neutral"
                      size="medium"
                    />
                    <WrapperButtons>
                      <Button
                        name="Remove Widget"
                        onClick={() => removeWidget()}
                        variant="danger"
                        size="medium"
                        icon={<Trash />}
                      />
                      <Button
                        name="Save"
                        onClick={() => changeWidget()}
                        variant="primary"
                        size="medium"
                        disabled={
                          selectedChart === widget?.id || !selectedChart
                        }
                        icon={<CheckCircle />}
                      />
                    </WrapperButtons>
                  </>
                ) : (
                  <>
                    <Button
                      name="Cancel"
                      onClick={handleOnClose}
                      variant="neutral"
                      size="medium"
                    />
                    <WrapperButtons>
                      <Button
                        name="Remove Widget"
                        onClick={() => removeWidget()}
                        variant="danger"
                        size="medium"
                        icon={<Trash />}
                      />
                      <Button
                        name="Save"
                        onClick={handleUpdateWidget}
                        disabled={_.isEqual(widgetData, currentWidget)}
                        variant={
                          _.isEqual(widgetData, currentWidget)
                            ? "neutral"
                            : "primary"
                        }
                        icon={<CheckCircle />}
                        size="medium"
                      />
                    </WrapperButtons>
                  </>
                )}
              </ModalFooterWrapper>
            </ModalWrapper>
          </ModalOverlay>
        ) : (
          <></>
        )}
      </ModalWrapper>
    </ModalOverlay>
  );
};
