import { AxiosError, AxiosResponse } from "axios";
import axios from "./axios";

import { DatasetDTO } from "../models/Datasets";
import {
  CREATE_DATASET,
  DELETE_DATASET_BY_ID,
  GET_DATASET_CONTENT_BY_ID,
  GET_DATASETS,
  UPDATE_DATASET_BY_ID,
} from "./constants";
import { generateQueryString } from "../helpers/queryGenerator";
import { RequestCallbacks } from "../models";

export type DatasetsQuery = {
  limit: number;
  skip: number;
  name: string;
};

export type DatasetQuery = {
  limit: number;
  skip: number;
  id: string;
  search?: string;
  callbacks?: RequestCallbacks<any>;
};

export const getDatasets = async ({
  limit,
  name,
  skip,
}: Partial<DatasetsQuery>) => {
  const queryParams = generateQueryString<DatasetsQuery>({ limit, name, skip });

  return axios
    .get(`${GET_DATASETS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const createDataset = async (dataset: DatasetDTO) => {
  return axios
    .post(CREATE_DATASET, dataset)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getDatasetContents = async ({
  id,
  limit,
  skip,
  search,
}: DatasetQuery) => {
  const queryParams = generateQueryString<DatasetQuery>({
    limit,
    search,
    skip,
  });
  return axios
    .get(`${GET_DATASET_CONTENT_BY_ID.replace("{id}", id)}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const removeDataset = async (datasetId: string) => {
  return axios
    .delete(DELETE_DATASET_BY_ID.replace("{id}", datasetId))
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const updateDatasetById = async ({
  id,
  patchData,
}: {
  id: string;
  patchData: any;
}) => {
  return axios
    .patch(UPDATE_DATASET_BY_ID.replace("{id}", id), patchData)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};
