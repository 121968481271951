import React, { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import {
  Buttons,
  CloseIcon,
  Container,
  DownIcon,
  DropDownMenuItem,
  DropDownMenuList,
  DropDownSelect,
  SelectContainer,
  SelectedValue,
} from "./style";
import { ISelect } from "./types";

type Props = {
  options: ISelect[];
  value: string;
  withOutClose?: boolean;
};

export const Select: React.FC<Props> = ({
  options,
  value,
  withOutClose,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<ISelect | undefined>(
    options?.find((item) => item.value === value)
  );
  const [activeDropDown, setActiveDropDown] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setActiveDropDown(false));

  const handleSelectItem = (item: ISelect) => {
    setSelectedValue(item);
    setActiveDropDown(false);
  };

  return (
    <Container ref={ref}>
      <SelectContainer>
        <SelectedValue onClick={() => setActiveDropDown((prev) => !prev)}>
          {selectedValue?.option || "Select value"}
        </SelectedValue>
        <Buttons>
          {!selectedValue && !withOutClose && (
            <CloseIcon
              onClick={() => setSelectedValue({ option: "", value: "" })}
            />
          )}
          <DownIcon
            $close={activeDropDown}
            onClick={() => setActiveDropDown((prev) => !prev)}
          />
        </Buttons>
      </SelectContainer>

      {!!activeDropDown && (
        <DropDownSelect>
          <DropDownMenuList>
            {options.map((item) => (
              <DropDownMenuItem onClick={() => handleSelectItem(item)}>
                {item.option}
              </DropDownMenuItem>
            ))}
          </DropDownMenuList>
        </DropDownSelect>
      )}
    </Container>
  );
};
