import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

export const LayerWrapper = styled.div<{$isCard: boolean}>`
  position: absolute;
  z-index: 1;
  ${({$isCard}) => $isCard? 
   "bottom: 16px; left: 0;" : 
   "top: 10px; right: 74px;"};
  background: transparent;
  max-width: ${({$isCard}) => $isCard? "calc(100% - 16px)" : "calc(100% - 75px);"};
`;

export const LayerOverlay = styled.div<{$isCard: boolean}>`
  background: #ffffff;
  border-radius: 24px;
  padding: 4px;
  border: ${({$isCard, theme}) => $isCard? "none":  `1px solid ${theme.neutralBorder}`};
  display: flex;
  flex-direction: ${({$isCard}) => $isCard? "row-reverse" : "row"};
  align-items: center;
`;

export const LayerBlock = styled.div<{$isCard: boolean}>`
  background: ${({ theme }) => theme.neutralBackgroundMedium};
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  
  width: ${({ $isCard }) => $isCard ? "100vh": "100%"};
  /* max-width: 250px; */
`;

export const LayerLine = styled.div<{ $selectedItem: boolean }>`
  background-color: ${({ $selectedItem, theme }) =>
    $selectedItem ? theme.secondaryButton : theme.neutralBorder};
  width: 2px;
  height: 100%;
  position: absolute;
  z-index: 1;
`;

export const LayerYearBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 3px;
  position: relative;
`;

export const LayerYear = styled.div<{ $selectedItem: boolean }>`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ $selectedItem, theme }) =>
    $selectedItem ? theme.secondaryText : theme.neutralWeak};
  border-radius: 8px;
  padding: 0 4px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  background-color: ${({ $selectedItem, theme }) =>
    $selectedItem ? theme.secondaryButton : theme.neutralBackgroundMedium};
`;

export const LayerButton = styled.button<{$isCard: boolean}>`
  background: transparent;
  display: flex;
  flex-direction: row;
  border: none;
  outline: none;
  cursor: pointer;

  ${({$isCard, theme}) => $isCard && ` 
    background-color: ${theme.neutralBackground};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    justify-content: center;
    align-items: center; 
  `}

`;

export const LayerButtonBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 8px;
  gap: 2px;
`;

export const LeftArrow = styled(ChevronLeft)<{$isCard: boolean}>`
${({$isCard}) => $isCard && `  
  width: 16px;
  height: 16px;`}
`

export const RightArrow = styled(ChevronRight)<{$isCard: boolean}>`
${({$isCard}) => $isCard && `  
  width: 16px;
  height: 16px;`}
`