import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { createPortal } from "react-dom";
import _ from "lodash";
import {
  NumberValue,
  axisBottom,
  axisLeft,
  scaleBand,
  scaleLinear,
  select,
  timeParse,
  timeFormat,
  symbol,
  symbolCircle,
  scaleOrdinal,
  symbolSquare,
  symbolCross,
  symbolTriangle,
} from "d3";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title,
} from "./styles";
import { HeadingNameAndButton } from "../styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { getActiveModal } from "../../../store/selectors/modals";

import { calculateNumTicks } from "../widgetHelpers";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { Tooltip } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { ScatterPlotGroupedData } from "./utils/getGroupData";
import { getCurrentColorV2 } from "../utils/getCurrentMarker";

export interface ScatterPlotProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  preview?: boolean;
}

const yAxisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

export const ScatterPlot = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  preview = false,
}: ScatterPlotProps) => {
  const dispatch = useDispatch();

  const svgRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure();
  const [measureRef, bounds] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<{
    name?: string;
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);

  const markerSize = 40; // is not not in px
  const margin = { top: 10, right: 5, bottom: 21, left: 40 };
  const width = (bounds.width || 1085) - margin.left - margin.right;
  const height = (bounds.height || 300) - margin.top - margin.bottom;

  const parseDate = timeParse("%Y");
  const format = timeFormat("%Y");
  const formatDate = useCallback(
    (date: string) => format(parseDate(date) as Date),
    [format, parseDate]
  );

  const scatterPlotSuggestion = aiSuggestions?.filter(
    (chart: AiSuggestionsDto) => chart.chartType === "scatterplot"
  )[0];

  const data: any = currentWidget?.data;
  const groupBy =
    currentWidget?.groupBy?.at(0) || scatterPlotSuggestion?.groupBy;

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : scatterPlotSuggestion.xAxe?.at(0);

  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : scatterPlotSuggestion.yAxe?.at(0);

  const xAxes =
    currentWidget.data?.reduce((t: any, l: any) => {
      if (!t?.includes(l[xAxe])) {
        return [...t, l[xAxe]];
      }
      return t;
    }, []) || [];

  let groupedData: any = ScatterPlotGroupedData(currentWidget);

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const uniqueValues =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : Object.keys(groupedData);

  const legendValues = [];
  if (groupedData && uniqueValues?.length) {
    for (let i = 0; i < uniqueValues?.length; i++) {
      const dataKey = uniqueValues[i];
      const color = getCurrentColorV2(currentWidget, dataKey, styleId);
      legendValues.push({ label: dataKey!, key: dataKey!, color });
    }
  }

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  //* Scales
  const xScale = scaleBand<number>()
    .domain(xAxes?.map((d: number) => d))
    .rangeRound([0, width])
    .paddingOuter(-0.5);

  const yScale = scaleLinear<number, number>()
    .domain([0, Math.max(...data.map((d: any) => d[yAxe])) || 0])
    .rangeRound([height, margin.top])
    .nice();

  const colorScale = scaleOrdinal<string, string>()
    .domain(uniqueValues)
    .range(legendValues?.map((item) => item.color))
    .unknown("#ccc");

  // const markerType = scaleOrdinal(symbolsFill);

  //* Marker
  const markersTypes = useMemo(
    () => currentWidget?.markers || [],
    [currentWidget?.markers]
  );

  const getMarkerType = useCallback(
    (key: string) =>
      String(markersTypes.find((item) => item.key === key)?.shape ?? ""),
    [markersTypes]
  );

  const getMarkerPath = (markerType: string) => {
    switch (markerType) {
      case "circle":
        return symbol().type(symbolCircle);
      case "donut":
        return symbol().type(symbolCircle);
      case "square":
        return symbol().type(symbolSquare);
      case "rhombus":
        return symbol().type(symbolSquare);
      case "triangle":
        return symbol().type(symbolTriangle);
      case "cross":
        return symbol().type(symbolCross);
      default:
        return symbol().type(symbolCircle);
    }
  };

  const getMarkerRotation = (markerType: string): string => {
    let deg = 0;
    switch (markerType) {
      case "rhombus":
      case "cross":
        deg = 45;
        break;
      default:
        deg = 0;
    }

    return `rotate(${deg})`;
  };

  const getMarkerStrokeWidth = (markerType: string): number => {
    let width = 0;
    switch (markerType) {
      case "donut":
        width = 1.5;
        break;
      default:
        width = 0;
    }

    return width;
  };

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget.tooltip) && !recommended) {
        setTooltip({
          name: datum[groupByKey],
          data: {
            [xAxe]: String(formatDate(datum[xAxe])),
            [yAxe]: String(datum[yAxe]),
          },
          x: event.clientX - 27,
          y: event.clientY - 85,
        });
      }
    },
    [
      currentWidget.tooltip,
      formatDate,
      groupByKey,
      recommended,
      showTooltip,
      xAxe,
      yAxe,
    ]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip) {
      setTooltip(null);
    }
  }, [setTooltip, showTooltip]);

  const handleMouseOver = useCallback(function (self: any, svg: any) {
    svg
      .selectAll(".scatterplot-marker")
      .transition()
      .duration(200)
      .attr("opacity", ".2");

    select(self).transition().duration(200).attr("opacity", "1");
  }, []);

  const handleMouseOut = useCallback(function (svg: any) {
    svg
      .selectAll(".scatterplot-marker")
      .transition()
      .duration(200)
      .attr("opacity", "1");
  }, []);

  //* Chart
  const svgContainer = select(svgRef.current)
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom);

  useEffect(() => {
    if (svgContainer.select("g").size()) {
      return;
    }

    const svg = svgContainer
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    //* yGrid
    svg
      .append("g")
      .attr("class", "grid")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(-width)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* y-axis
    svg
      .append("g")
      .attr("class", "y-axis")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(0)
          .tickPadding(8)
          .tickFormat(yAxisTickFormatter)
      )
      .call((g) =>
        g
          .select(".domain")
          .attr("stroke", "#ccc")
          .attr("stroke-dasharray", "1,2")
      )
      .selectAll("text")
      .attr("dx", `-${margin.left - 10}px`)
      .style("fill", "#5f6877")
      .style("font-size", "11px")
      .style("text-anchor", "start");

    // y-axis tick lines
    svg
      .selectAll(".y-axis .tick")
      .append("line")
      .attr("class", "tick-line")
      .call((g) => g.select(".domain").remove())
      .attr("x1", -8)
      .attr("x2", 0)
      .attr("y1", 0)
      .attr("y2", 0)
      .attr("stroke", "#939ba7")
      .attr("stroke-width", "1px");

    //* xGrid
    svg
      .append("g")
      .attr("class", "grid")
      .call(
        axisBottom(xScale)
          .tickSize(height)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* x-axis
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(
        axisBottom(xScale)
          .tickSizeOuter(0)
          .tickSize(0)
          .tickPadding(12)
          .tickFormat((d) => formatDate(String(d)))
      )
      .call((g) => g.select(".domain").attr("stroke", "#939ba7"))
      .selectAll("text")
      .style("fill", "#5f6877")
      .style("font-size", "11px");

    // * DataViz
    // Scatter Markers
    svg
      .append("g")
      .selectAll("path")
      .attr("class", "scatterplot-marker-group")
      .data(data)
      .join("g")
      .attr("class", "scatterplot-container")
      .each(function (d: any) {
        const markerContainer = select(this);
        const markerType = getMarkerType(d[groupByKey]);

        markerContainer
          .append("path")
          .attr("class", "scatterplot-marker")
          .attr("d", getMarkerPath(markerType).size(markerSize))
          .attr(
            "transform",
            (d: any) =>
              `translate(${
                (xScale(d[xAxe]) || 0) + xScale.bandwidth() / 2
              },${yScale(d[yAxe])}) ${getMarkerRotation(markerType)}`
          )
          .attr("fill", (d: any) =>
            markerType === "donut" ? "none" : colorScale(d[groupByKey])
          )
          .attr("stroke", (d: any) => colorScale(d[groupByKey]))
          .attr("stroke-width", getMarkerStrokeWidth(markerType));
      })
      .on("mouseover", function () {
        handleMouseOver(this, svg);
      })
      .on("mouseout", () => {
        handleMouseOut(svg);
      })
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);
  }, [
    colorScale,
    data,
    formatDate,
    getMarkerType,
    groupByKey,
    handleMouseLeave,
    handleMouseMove,
    handleMouseOut,
    handleMouseOver,
    height,
    margin.left,
    margin.top,
    markersTypes,
    numTicks,
    svgContainer,
    width,
    xAxe,
    xScale,
    yAxe,
    yScale,
  ]);

  if (_.isEmpty(groupedData)) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <></>}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["scatterPlot"]?.length + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 && showLegend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="unit"
            legendValues={legendValues}
          />
        )}
      </HeaderWrapper>

      <SVGStyled
        ref={(node) => {
          svgRef.current = node;
          measureRef(node);
        }}
        width="100%"
        height="100%"
      ></SVGStyled>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
          />,
          document.body
        )}
    </>
  );
};
