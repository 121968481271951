import styled from "styled-components";

const TruncatedText = styled.text`
  display: block;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Label = styled.div`
  text-anchor: start;
  font-size: 11px;
  font-weight: 400;
  color: #59616f;
  overflow: hidden;
  display: block;
  position: absolute;
  text-overflow: ellipsis;
  background: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const getRelativePosition = (element: any, event: any) => {
  const rect = element.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
};

export const getClientPosition = (event: any) => {
  return {
    x: event.pageX,
    y: event.pageY,
  };
};

export const TickLabel = ({
  formattedValue,
  length = 5,
  setTooltip,
  offsetX = 0,
  offsetY = 0,
  dy = 0,
  ...tickProps
}: any) => {
  const splitedVal =
    typeof formattedValue === "number"
      ? formattedValue?.toString().split("")
      : formattedValue?.split("");
  return (
    <TruncatedText {...tickProps}>
      <tspan
        onMouseEnter={(e: any) => {
          const { x, y } = getRelativePosition(e.target.closest("svg"), e);
          if (splitedVal?.length > length && setTooltip) {
            setTooltip({
              data: formattedValue,
              x: x + offsetX,
              y: y + offsetY,
            });
          }
        }}
        onMouseLeave={() => setTooltip && setTooltip(null)}
        x="0"
        dy={dy || "0"}
      >
        {splitedVal?.slice(0, length).join("")}
        {splitedVal?.length <= length ? "" : "..."}
      </tspan>
    </TruncatedText>
  );
};

export const LabelTooltip = ({
  x,
  y,
  data,
}: {
  x: number;
  y: number;
  data: string;
}) => {
  return <Label style={{ left: x + "px", top: y + "px" }}>{data}</Label>;
};
