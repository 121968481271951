import {
  IFormatting,
  IMarkersType,
  WidgetItem
} from '../../../../models/Widgets';

export const ScatterPlotGroupedData = (
  currentWidgetData?: WidgetItem,
  scatterPlotSuggestion?: any
) => {
  const groupBy = currentWidgetData?.groupBy?.at(0);

  const xAxe = currentWidgetData?.xAxe?.length
    ? currentWidgetData?.xAxe?.at(0)
    : scatterPlotSuggestion.xAxe?.at(0);

  const yAxe = currentWidgetData?.yAxe?.length
    ? currentWidgetData?.yAxe?.at(0)
    : scatterPlotSuggestion.yAxe?.at(0);

  if (groupBy) {
    return currentWidgetData?.data?.reduce((acc, obj) => {
      const group = obj[groupBy];
      acc[group] = acc[group] || [];
      acc[group].push({
        x: xAxe ? obj[xAxe] : obj.year,
        y: yAxe ? obj[yAxe] : obj.value
      });
      return acc;
    }, {});
  } else {
    return {
      default: currentWidgetData?.data?.map((l) => ({
        x: xAxe ? l[xAxe] : l.year,
        y: yAxe ? l[yAxe] : l.value
      }))
    };
  }
};

export const ScatterPlotDefaultMarkers = (data: any): IMarkersType[] => {
  const groupBy = data?.groupBy?.at(0);
  const uniqueValuesKeys =
    (data?.uniqueValues && Object.keys(data?.uniqueValues!)) || [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);
  const groupedData = data?.uniqueValues
    ? data?.uniqueValues[groupByKey]
    : ScatterPlotGroupedData(data);

  if (data.uniqueValues) {
    const markers: IMarkersType[] = groupedData.map(
      (item: string, index: number) => ({
        key: item,
        shape: 'circle'
      })
    );
    return markers;
  } else {
    const markers: IMarkersType[] = Object.keys(groupedData).map(
      (item: string, index: number) => ({
        key: item,
        shape: 'circle'
      })
    );

    return markers;
  }
};

export const getScatterPlotDefaultColors = (data: any): IFormatting[] => {
  const groupedData = data.uniqueValues || ScatterPlotGroupedData(data);

  if (data.uniqueValues) {
    const colors: IFormatting[] = groupedData.map(
      (item: string, index: number) => ({
        key: item,
        color: String(index + 1)
      })
    );

    return colors;
  } else {
    const colors: IFormatting[] = Object.keys(groupedData).map(
      (item: string, index: number) => ({
        key: item,
        color: String(index + 1)
      })
    );

    return colors;
  }
};
