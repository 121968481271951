import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMeasure from 'react-use-measure';
import { createPortal } from 'react-dom';
import _ from 'lodash';
import {
  axisBottom,
  axisLeft,
  scaleBand,
  scaleLinear,
  select,
  timeParse,
  timeFormat
} from 'd3';

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title
} from './styles';
import { HeadingNameAndButton } from '../styles';

import { setActiveModal } from '../../../store/slices/modals';
import { getAiSuggestions } from '../../../store/selectors/widgets';
import { getIsEditMode, getIsPublicMode } from '../../../store/selectors/main';
import {
  getCurrentWidget,
  getPageSettings
} from '../../../store/selectors/projects';
import { setCurrentWidget } from '../../../store/slices/projectPages';
import { getActiveModal } from '../../../store/selectors/modals';

import { calculateNumTicks } from '../widgetHelpers';
import { AiSuggestionsDto, WidgetItem } from '../../../models/Widgets';
import { ChartLegend } from '../../ChartLegend';
import { Tooltip } from '../Tooltip';
import { Loader } from '../../Loader';
import { SelectBage } from '../SelectBage';
import { replaceWords } from '../../../helpers/replaceName';
import { AVAILABLE_WIDGETS } from '../../../constants/widgetRecomended';
import { PunchcardChartGroupedData } from './utils/getGroupData';
import { getCurrentColorV2 } from '../utils/getCurrentMarker';
import { generateColorRanges } from './utils/generateColorRanges';
import { ColorRangeI } from '../../../models/Pages';
import { colorsPalettes } from '../../../constants';
import { LabelTooltip } from '../components/LabelTooltip';

export interface PunchcardChartProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  preview?: boolean;
}

const bubbleLabelFormatter = (value: number): string =>
  Intl.NumberFormat('en-US', {
    notation: 'compact'
  }).format(value as number);

const yAxisTickFormatter = (
  value: string,
  length: number = Infinity
): string => {
  const splitedValue = value?.split('');

  return `${splitedValue?.slice(0, length).join('')}${
    splitedValue?.length <= length ? '' : '...'
  }`;
};

export const PunchcardChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  preview = false
}: PunchcardChartProps) => {
  const dispatch = useDispatch();

  const svgRef = useRef<any>(null);
  const xAxisRef = useRef<any>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [refWidget, boundsWidget] = useMeasure();
  const [measureRef, bounds] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [values, setValues] = useState<number[]>([]);
  const [variations, setVariations] = useState<string[]>([]);
  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const [tooltip, setTooltip] = useState<{
    name?: string;
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);
  const [tickLabelTooltip, setTickLabelTooltip] = useState<{
    data: string;
    x: number;
    y: number;
  } | null>(null);

  const maxLengthYAxisTickLabel = 12;
  const minBubbleRadius = 8;
  const minBubbleRadiusVisibleLabel = 16;
  const maxBubbleRadius = 30;
  const margin = { top: 10, right: 0, bottom: 21, left: 80 };
  margin.right += maxBubbleRadius / 2;
  margin.left += maxBubbleRadius;

  const data: any = currentWidget?.data;
  const punchcardChartSuggestion = aiSuggestions?.filter(
    (chart: AiSuggestionsDto) => chart.chartType === 'punchcardChart'
  )[0];
  const groupBy =
    currentWidget?.groupBy?.at(0) || punchcardChartSuggestion?.groupBy;

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : punchcardChartSuggestion.xAxe?.at(0);

  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : punchcardChartSuggestion.yAxe?.at(0);

  const xAxes =
    currentWidget.data?.reduce((t: any, l: any) => {
      if (!t?.includes(l[xAxe])) {
        return [...t, l[xAxe]];
      }
      return t;
    }, []) || [];

  const groupedData: any = PunchcardChartGroupedData(currentWidget);

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const uniqueValues =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : Object.keys(groupedData);

  const legendValues = [];
  if (groupedData && uniqueValues?.length) {
    for (let i = 0; i < uniqueValues?.length; i++) {
      const dataKey = uniqueValues[i];
      const color = getCurrentColorV2(currentWidget, dataKey, styleId);
      legendValues.push({ label: dataKey!, key: dataKey!, color });
    }
  }

  const width = (bounds.width || 1085) - margin.left - margin.right;
  const height =
    uniqueValues.length * maxBubbleRadius * 2 ||
    (bounds.height || 300) - margin.top - margin.bottom;

  const refHeight = useMemo(
    () => divRef?.current?.parentElement?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, divRef.current]
  );

  const hasChartYOverflow = (uniqueValues.length || 1) > 5;

  const parseDate = timeParse('%Y');
  const format = timeFormat('%Y');
  const formatDate = useCallback(
    (date: string) => format(parseDate(date) as Date),
    [format, parseDate]
  );

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  const generateColorRangesCallback = useCallback(
    () => generateColorRanges(variations, values, setColorRanges),
    [variations, values]
  );

  useEffect(() => {
    const values = data?.map((item: any) => item.value || '0');
    const colorPalette = colorsPalettes.find(
      (palette) => palette.id === (styleId ?? 'default')
    );
    const colorPaletteVariations = colorPalette?.colors[0].variations || [];

    setValues(values);
    setVariations(colorPaletteVariations?.slice()?.reverse());
  }, [data, styleId]);

  useEffect(() => {
    if (variations?.length) {
      generateColorRangesCallback();
    }
  }, [variations?.length, values, generateColorRangesCallback]);

  //* Scales
  const minValue = Math.min(...data.map((d: any) => d[yAxe])) || 0;
  const maxValue = Math.max(...data.map((d: any) => d[yAxe])) || 0;

  const xScale = scaleBand<number>()
    .domain(xAxes?.map((d: number) => d))
    .rangeRound([maxBubbleRadius, width - maxBubbleRadius])
    .paddingOuter(-0.5);

  const yScale = scaleBand<string>()
    .domain(uniqueValues)
    .range([0, height])
    .paddingOuter(-0.5);

  const rScale = scaleLinear<number, number>()
    .domain([minValue, maxValue])
    .rangeRound([minBubbleRadius, maxBubbleRadius])
    .nice();

  const domain: any[] = [];
  const ranges: string[] = [];
  colorRanges.forEach((range, index) => {
    if (index === 0) {
      domain.push(range.start);
    }
    domain.push(range.end);
    ranges.push(range.color);
  });

  const colorScale = scaleLinear<string, string>()
    .domain(domain)
    .range(ranges)
    .unknown('#ccc');

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget.tooltip) && !recommended) {
        setTooltip({
          name: datum[groupByKey],
          data: {
            [xAxe]: String(formatDate(datum[xAxe])),
            [yAxe]: String(datum[yAxe])
          },
          x: event.clientX - 27,
          y: event.clientY - 85
        });
      }
    },
    [
      currentWidget.tooltip,
      formatDate,
      groupByKey,
      recommended,
      showTooltip,
      xAxe,
      yAxe
    ]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip) {
      setTooltip(null);
    }
  }, [setTooltip, showTooltip]);

  const handleMouseMoveTickLabel = useCallback((event: any, datum: any) => {
    setTickLabelTooltip({
      data: datum,
      x: event.layerX - 27,
      y: event.layerY - 27
    });
  }, []);

  const handleMouseLeaveTickLabel = useCallback(() => {
    setTickLabelTooltip(null);
  }, []);

  const handleMouseOver = useCallback(function (self: any, svg: any) {
    svg
      .selectAll('.buble-container')
      .transition()
      .duration(200)
      .attr('opacity', '.2');

    select(self).transition().duration(200).attr('opacity', '1');
  }, []);

  const handleMouseOut = useCallback(function (svg: any) {
    svg
      .selectAll('.buble-container')
      .transition()
      .duration(200)
      .attr('opacity', '1');
  }, []);

  //* Chart
  const svgContainer = select(svgRef.current)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom);

  const xAxisContainer = select(xAxisRef.current);

  useEffect(() => {
    if (!colorRanges?.length || svgContainer.select('g').size()) {
      return;
    }

    const svg = svgContainer
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    //* yGrid
    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(-width)
          .tickFormat(() => '')
      )
      .call((g) => g.select('.domain').remove())
      .selectAll('line')
      .attr('stroke', '#ccc')
      .attr('stroke-dasharray', '1,2')
      .attr('stroke-width', '1px');

    //* y-axis
    svg
      .append('g')
      .attr('class', 'y-axis')
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(0)
          .tickPadding(8)
          .tickFormat((s: string) =>
            yAxisTickFormatter(s, maxLengthYAxisTickLabel)
          )
      )
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      .each(function (this: any) {
        const tickLabel = select(this);

        tickLabel
          .attr('class', 'tick-label')
          .attr('dx', `-${margin.left - 10}px`)
          .style('fill', '#5f6877')
          .style('font-size', '11px')
          .style('text-anchor', 'start')
          .on('mousemove', handleMouseMoveTickLabel)
          .on('mouseleave', handleMouseLeaveTickLabel);
      });

    // y-axis tick lines
    svg
      .selectAll('.y-axis .tick')
      .append('line')
      .attr('class', 'tick-line')
      .call((g) => g.select('.domain').remove())
      .attr('x1', -8)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', 0)
      .attr('stroke', '#939ba7')
      .attr('stroke-width', '1px');

    //* xGrid
    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        axisBottom(xScale)
          .tickSize(height)
          .tickFormat(() => '')
      )
      .call((g) => g.select('.domain').remove())
      .selectAll('line')
      .attr('stroke', '#ccc')
      .attr('stroke-dasharray', '1,2')
      .attr('stroke-width', '1px');

    //* x-axis
    if (!hasChartYOverflow) {
      svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0,${height})`)
        .call(
          axisBottom(xScale)
            .tickSizeOuter(0)
            .tickSize(0)
            .tickPadding(12)
            .tickFormat((d) => formatDate(String(d)))
        )
        .call((g) => g.select('.domain').attr('stroke', '#939ba7'))
        .selectAll('text')
        .style('fill', '#5f6877')
        .style('font-size', '11px');
    } else {
      xAxisContainer
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(${margin.left},0)`)
        .call(
          axisBottom(xScale)
            .tickSizeOuter(0)
            .tickSize(0)
            .tickPadding(12)
            .tickFormat((d) => formatDate(String(d)))
        )
        .call((g) => g.select('.domain').attr('stroke', '#939ba7'))
        .selectAll('text')
        .style('fill', '#5f6877')
        .style('font-size', '11px');
    }

    // * DataViz
    // Bubble Container
    svg
      .append('g')
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('class', 'buble-container')
      .each(function (d: any) {
        const bubbleContainer = select(this);

        // Bubble
        bubbleContainer
          .append('circle')
          .attr('class', 'buble')
          .attr(
            'cx',
            (d: any) => (xScale(d[xAxe]) || 0) + xScale.bandwidth() / 2
          )
          .attr(
            'cy',
            (d: any) => (yScale(d[groupByKey]) || 0) + yScale.bandwidth() / 2
          )
          .attr('r', (d: any) => rScale(d[yAxe]))
          .attr('fill', (d: any) => colorScale(d[yAxe]))
          .attr('fill-opacity', 0.2)
          .attr('stroke', (d: any) => colorScale(d[yAxe]))
          .attr('stroke-width', 1);

        // Bubble Label
        bubbleContainer
          .append('text')
          .attr('class', 'buble-label')
          .attr('x', (xScale(d[xAxe]) || 0) + xScale.bandwidth() / 2)
          .attr('y', (yScale(d[groupByKey]) || 0) + yScale.bandwidth() / 2)
          .attr('font-size', '12px')
          .attr('fill', '#4e4e4e')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .text(() =>
            rScale(d[yAxe]) >= minBubbleRadiusVisibleLabel
              ? bubbleLabelFormatter(d[yAxe])
              : ''
          );
      })
      .on('mouseover', function () {
        handleMouseOver(this, svg);
      })
      .on('mouseout', () => {
        handleMouseOut(svg);
      })
      .on('mousemove', handleMouseMove)
      .on('mouseleave', handleMouseLeave);
  }, [
    colorRanges?.length,
    colorScale,
    data,
    formatDate,
    groupByKey,
    handleMouseLeave,
    handleMouseLeaveTickLabel,
    handleMouseMove,
    handleMouseMoveTickLabel,
    handleMouseOut,
    handleMouseOver,
    hasChartYOverflow,
    height,
    margin.left,
    margin.top,
    numTicks,
    rScale,
    svgContainer,
    width,
    xAxe,
    xAxisContainer,
    xScale,
    yAxe,
    yScale
  ]);

  if (_.isEmpty(groupedData)) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <></>}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: 'recommendedWidgetsModal' }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS['bubbleChart']?.length + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 && showLegend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="palette"
            colorRanges={colorRanges}
          />
        )}
      </HeaderWrapper>

      <div
        ref={divRef}
        style={
          hasChartYOverflow
            ? {
                height: refHeight
                  ? refHeight - (63 + 10 + 24 + 22) < 170
                    ? 170
                    : refHeight - (63 + 10 + 24 + 22)
                  : 170,
                overflowY: 'scroll'
              }
            : { height: refHeight ? refHeight - (63 + 10 + 12) : '100%' }
        }
      >
        <SVGStyled
          ref={(node) => {
            svgRef.current = node;
            measureRef(node);
          }}
          width="100%"
          height="100%"
        ></SVGStyled>
      </div>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
          />,
          document.body
        )}

      {hasChartYOverflow && (
        <svg
          ref={xAxisRef}
          className="axis-test"
          width="100%"
          height="22"
        ></svg>
      )}

      {tickLabelTooltip && (
        <LabelTooltip
          x={tickLabelTooltip?.x}
          y={tickLabelTooltip?.y}
          data={tickLabelTooltip?.data}
        />
      )}
    </>
  );
};
