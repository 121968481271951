import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "leaflet/dist/leaflet.css";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  LoaderWrapper,
  MapWrapper,
  SelectBageBlock,
} from "./styles";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  SettingsButtonWrapper,
} from "../VerticalBarchart/styles";
import { mapInitialization } from "./Config/mapInitialization";
import { MapLegend } from "../../MapLegend";
import { setActiveModal } from "../../../store/slices/modals";
import { trackZoomLevel } from "./Config/trackZoomLevel";
import { geoMarker } from "./Config/marker";
import { mapLayers } from "./Config/layers";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getPageSettings } from "../../../store/selectors/projects";
import { colorsPalettes } from "../../../constants";
import { MapYear } from "../../MapYear";
import {
  MarkersVisualisationDataDto,
  WidgetItem,
} from "../../../models/Widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { ColorRangeI } from "../../../models/Pages";
import { Loader } from "../../Loader";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { generateBreakPoints } from "../../../helpers/generateBreakPoints";
import useMeasure from "react-use-measure";

interface MapChartInterface {
  showSettings?: boolean;
  showLegend?: boolean;
  recommended?: boolean;
  selected?: boolean;
  isFullScreen?: boolean;
  currentWidget: WidgetItem;
  uniqueMapId?: string;
}

export const LeafletMap = ({
  showSettings = true,
  showLegend = true,
  recommended = false,
  selected = false,
  isFullScreen = false,
  currentWidget,
  uniqueMapId,
}: MapChartInterface) => {
  const isEditMode = useSelector(getIsEditMode);
  const [mapType] = useState<"counties" | "states">("counties");
  const dispatch = useDispatch();
  const isPublicRoute = useSelector(getIsPublicMode);
  const mapRef = useRef<any>();
  const [ref, bounds] = useMeasure();
  const geoJSONLayersRef = useRef<any[]>([]);
  const markersRef = useRef<any[]>([]);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [layerData, setLayerData] = useState<MarkersVisualisationDataDto[]>([]);
  const [years, setYears] = useState<number[] | []>([]);
  const [year, setYear] = useState<number | undefined>();
  const [average, setAverage] = useState<number | undefined>();
  const [values, setValues] = useState<number[]>([]);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const currentStyle = styleId || "default";
  const colorPalette = colorsPalettes.find(
    (palette) => palette.id === currentStyle
  );
  const colorPaletteVariations = colorPalette?.colors[0].variations;

  const [leftSlider, setLeftSlider] = useState(0);
  const [rightSlider, setRightSlider] = useState(6);

  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const isCard = currentWidget.layout === "card";

  const id = useMemo(
    () => (recommended ? "recommended_map" : !!uniqueMapId ? uniqueMapId : "map"),
    [recommended, uniqueMapId]
  );

  const generateColorRanges = useCallback(() => {
    if (colorPaletteVariations?.length && values) {
      const colors = colorPaletteVariations?.slice()?.reverse();
      const sortedValues = [...new Set(values)].sort();
      const steps = generateBreakPoints(sortedValues);
      const colorRanges = steps?.map((c, index) => {
        const end = index === steps?.length - 1 ? c * 3 : steps[index + 1];
        return {
          color:
            index >= leftSlider && index <= rightSlider
              ? colors[index]
              : "#E0DDDC",
          start: parseInt(c?.toString()),
          end: parseInt(end?.toString()),
        };
      });
      setColorRanges(colorRanges);
    }
  }, [colorPaletteVariations, values, leftSlider, rightSlider]);

  useEffect(() => {
    if (colorPaletteVariations?.length) {
      generateColorRanges();
    }
  }, [colorPaletteVariations?.length, values, generateColorRanges]);

  useEffect(() => {
    if (!(mapRef && mapRef.current) && currentWidget?.id && id) {
      try {
        mapInitialization(mapRef, id, isFullScreen, currentWidget.terrain);
      } catch (e) {}
    }
    trackZoomLevel({ map: mapRef.current, setZoomLevel: setZoomLevel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWidget?.id, id]);

  useEffect(() => {
    if (currentWidget) {
      const layerData = currentWidget?.layers?.at(0)?.data;
      const values: number[] = [];
      const sum =
        layerData?.reduce((acc, curr) => {
          const value = parseFloat(curr.value);
          values.push(value);
          return acc + value;
        }, 0) || 0;
      setValues(values);
      const average = sum / (layerData?.length || 1);
      setAverage(average);
      setLayerData(layerData || []);
      const mapedYears = layerData?.reduce(
        (t: string[], l: MarkersVisualisationDataDto) => {
          if (!t.includes(l.year)) {
            return [l.year, ...t];
          }
          return t;
        },
        []
      );
      const years = mapedYears
        ?.map((y) => parseInt(y))
        ?.filter(
          (y) => y && y !== Infinity && y !== -Infinity && !Number.isNaN(y)
        );
      years?.sort();
      if (years?.length) {
        setYear(years[0]);
        setYears(years || []);
      }
    }
  }, [currentWidget]);

  useEffect(() => {
    geoMarker({
      markersRef,
      mapRef,
      zoomLevel,
      mapType,
      aiSuggestions,
    });
  }, [zoomLevel, mapType, aiSuggestions]);

  useEffect(() => {
    if (colorRanges?.length && mapRef && mapRef.current) {
      const data = layerData?.filter((d) => parseInt(d.year) === year);
      const widgetTooltip = currentWidget.tooltip;
      const widgetPreview = recommended;

      mapLayers({
        geoJSONLayersRef,
        mapRef,
        mapType,
        colorRanges,
        data,
        showTooltip,
        widgetTooltip,
        widgetPreview,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapType, colorRanges, year, layerData]);

  useEffect(() => {
    if (mapRef.current) {
      const mapInstance = mapRef.current.leafletElement || mapRef.current;
      mapInstance.invalidateSize();
    }
  }, [bounds.height, bounds.width]);

  return (
    <Suspense>
      <Container>
        <MapWrapper
          ref={ref}
          id={id}
          style={{
            position: "absolute",
            width: `calc(100% - ${isCard ? "32px" : "0"})`,
            height: `calc(100% - ${
              isCard
                ? recommended || !currentWidget.timeline
                  ? "56px"
                  : "114px"
                : "0"
            })`,
            top: 0,
            left: 0,
            margin: `${
              isCard
                ? recommended || !currentWidget.timeline
                  ? "40px 16px 16px"
                  : "40px 16px 74px"
                : "0px"
            }`,
            borderRadius: "6px",
            border: `1px solid rgb(211,219,227)`,
          }}
        />
        {layerData?.length ? (
          <>
            {!isPublicRoute && isEditMode && !recommended ? (
              <SettingsButtonWrapper
                $modalOpen={!showSettings}
                $map
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["mapChart"]?.length + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {showLegend ? (
              <MapLegend
                colorRanges={colorRanges}
                average={average!}
                leftSlider={leftSlider}
                rightSlider={rightSlider}
                setLeftSlider={setLeftSlider}
                setRightSlider={setRightSlider}
                timeline={currentWidget.timeline!}
                isCard={isCard}
              />
            ) : null}
            {years?.length && currentWidget.timeline && !recommended ? (
              <MapYear
                years={years}
                setYear={setYear}
                selectedYear={year!}
                isCard={isCard}
              />
            ) : null}
            {recommended ? (
              <SelectBageBlock>
                <SelectBage selected={selected} />
              </SelectBageBlock>
            ) : null}
          </>
        ) : (
          <LoaderWrapper>
            <Loader blur={false} />
          </LoaderWrapper>
        )}
      </Container>
    </Suspense>
  );
};
