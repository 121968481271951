import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMeasure from 'react-use-measure';
import { createPortal } from 'react-dom';
import _ from 'lodash';
import {
  NumberValue,
  max,
  scaleLinear,
  scaleOrdinal,
  select,
  timeParse,
  timeFormat,
  range,
  arc
} from 'd3';

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title
} from './styles';
import { HeadingNameAndButton } from '../styles';

import { setActiveModal } from '../../../store/slices/modals';
import { getAiSuggestions } from '../../../store/selectors/widgets';
import { getIsEditMode, getIsPublicMode } from '../../../store/selectors/main';
import {
  getCurrentWidget,
  getPageSettings
} from '../../../store/selectors/projects';
import { setCurrentWidget } from '../../../store/slices/projectPages';
import { getActiveModal } from '../../../store/selectors/modals';

import { AiSuggestionsDto, WidgetItem } from '../../../models/Widgets';
import { ChartLegend } from '../../ChartLegend';
import { Tooltip } from '../Tooltip';
import { Loader } from '../../Loader';
import { SelectBage } from '../SelectBage';
import { replaceWords } from '../../../helpers/replaceName';
import { AVAILABLE_WIDGETS } from '../../../constants/widgetRecomended';
import { getCurrentColorV2 } from '../utils/getCurrentMarker';

export interface PolarAreaChartProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  preview?: boolean;
}

const axisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat('en-US', {
    notation: 'compact'
  }).format(value as number);

export const PolarAreaChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  preview = false
}: PolarAreaChartProps) => {
  const dispatch = useDispatch();

  const svgRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure();
  const [measureRef, bounds] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<{
    name?: string;
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);

  const margin = { top: 25, right: 5, bottom: 25, left: 5 };
  const width = (bounds.width || 1085) - margin.left - margin.right;
  const height = (bounds.height || 300) - margin.top - margin.bottom;
  const innerRadius = 0;
  const outerRadius = Math.min(width, height) / 2;
  const rScaleSteps = 5;

  const parseDate = timeParse('%Y');
  const format = timeFormat('%Y');
  const formatDate = useCallback(
    (date: string) => format(parseDate(date) as Date),
    [format, parseDate]
  );

  const polarAreaSuggestion = aiSuggestions?.filter(
    (chart: AiSuggestionsDto) => chart.chartType === 'polarAreaChart'
  )[0];

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : polarAreaSuggestion.xAxe?.at(0);

  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : polarAreaSuggestion.yAxe?.at(0);

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];

  const uniqueValues =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[uniqueValuesKeys?.at(0)!]
      : [];

  const legendValues = [];
  if (uniqueValues?.length) {
    for (let i = 0; i < uniqueValues?.length; i++) {
      const dataKey = uniqueValues[i];
      const color = getCurrentColorV2(currentWidget, dataKey, styleId);
      legendValues.push({ label: dataKey!, key: dataKey!, color });
    }
  }

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  //* Chart Data
  const chartData = currentWidget?.data || polarAreaSuggestion.data;

  //* Scales
  const colorScale = scaleOrdinal<string, string>()
    .domain(legendValues?.map((item) => item.label))
    .range(legendValues?.map((item) => item.color))
    .unknown('#ccc');

  const maxValue = max(chartData, (d: any) => d[yAxe] as number) as number;

  const rScale = scaleLinear<number, number>()
    .range([0, outerRadius])
    .domain([0, maxValue])
    .nice();

  const xScale = scaleLinear<number, number>()
    .domain([0, chartData.length])
    .range([0, 2 * Math.PI]);

  const yScale = scaleLinear<number, number>()
    .domain([0, max(chartData, (d: any) => Math.ceil(d[yAxe])) as NumberValue])
    .range([innerRadius, outerRadius]);

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget.tooltip) && !recommended) {
        setTooltip({
          name: String(formatDate(datum[xAxe!])),
          data: {
            value: String(datum[yAxe!])
          },
          x: event.clientX - 27,
          y: event.clientY - 75
        });
      }
    },
    [currentWidget.tooltip, formatDate, recommended, showTooltip, xAxe, yAxe]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip) {
      setTooltip(null);
    }
  }, [setTooltip, showTooltip]);

  const handleMouseOver = function (self: any, svg: any) {
    svg
      .selectAll('.polar-area-path, .tick-group')
      .transition()
      .duration(200)
      .attr('opacity', 0.2);

    select(self).transition().duration(200).attr('opacity', '1').attr('r', 6);
  };

  const handleMouseOut = function (svg: any) {
    svg
      .selectAll('.polar-area-path')
      .transition()
      .duration(200)
      .attr('opacity', 0.8);

    svg.selectAll('.tick-group').transition().duration(200).attr('opacity', 1);
  };

  //* Chart
  const svgContainer = select(svgRef.current)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom);

  useEffect(() => {
    if (svgContainer.select('g').size()) {
      return;
    }

    const categories =
      currentWidget.data?.reduce((t: any, l: any) => {
        if (!t?.includes(l[xAxe])) {
          return [...t, l[xAxe]];
        }
        return t;
      }, []) || [];

    const angleSlice = (Math.PI * 2) / categories.length;

    const svg = svgContainer
      .append('g')
      .attr(
        'transform',
        `translate(${(width + margin.left + margin.right) / 2}, ${
          (height + margin.top + margin.bottom) / 2
        })`
      );

    //* Grid
    const grid = svg.append('g').attr('class', 'grid');
    grid
      .selectAll('.grid-circle')
      .data(range(1, rScaleSteps + 1))
      .enter()
      .append('circle')
      .attr('class', 'grid-circle')
      .attr('r', (d) => rScale((d * maxValue) / rScaleSteps))
      .style('fill', 'none')
      .attr('stroke', '#939ba7')
      .attr('stroke-opacity', (d, i) => (i + 1) * 0.2);

    //* Axes
    const axisGrid = svg.append('g').attr('class', 'axis-grid');
    categories.forEach((category: string, i: number) => {
      // INFO: Start from the top: angleSlice * i - Math.PI / 2
      const angle = angleSlice * i - Math.PI / 2;
      const x = rScale(maxValue) * Math.cos(angle);
      const y = rScale(maxValue) * Math.sin(angle);

      // Radial axis line
      axisGrid
        .append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', x)
        .attr('y2', y)
        .attr('class', 'grid')
        .style('stroke', '#939ba7')
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-dasharray', '2,2');

      // Radial axis line's cap
      axisGrid
        .append('circle')
        .attr('cx', x)
        .attr('cy', y)
        .attr('r', 3)
        .attr('class', 'axis-cap')
        .style('fill', '#939ba7');

      // Axis labels
      const LabelPosAngle = angleSlice * i + angleSlice / 2 - Math.PI / 2;
      const labelPosX = rScale(maxValue) * Math.cos(LabelPosAngle);
      const labelPosY = rScale(maxValue) * Math.sin(LabelPosAngle);
      axisGrid
        .append('text')
        .attr('class', 'axis-label')
        .attr('x', labelPosX * 1.2)
        .attr('y', labelPosY * 1.2)
        .attr('text-anchor', 'middle')
        .attr('font-size', '12')
        .attr('dominant-baseline', 'central')
        .attr('fill', '#5f6877')
        .text(category);
    });

    // * DataViz
    // Draw data areas
    svg
      .selectAll('g')
      .data(chartData)
      .join('g')
      .attr('fill', (d: any) => colorScale(d[xAxe]) as string)
      .selectAll('path')
      .data(
        (d: any, i: number) => [
          {
            year: d[xAxe],
            value: d[yAxe],
            pos: i
          }
        ]
        // For sliced areas
        // range(yTickSpacing, d.value + 1, yTickSpacing).map((value) => ({
        //   year: d[xAxe],
        //   value: value,
        //   pos: i
        // }))
      )
      .join('path')
      .attr('class', 'polar-area-path')
      .attr('opacity', 0.8)
      .attr('stroke', '#fff')
      .attr('stroke-width', 2)
      .attr('d', (d) =>
        arc()
          .innerRadius(innerRadius)
          // For sliced areas
          // .innerRadius(y(d.value - yTickSpacing))
          .outerRadius(yScale(d.value))
          .startAngle(xScale(d.pos))
          .endAngle(xScale(d.pos + 1))(d as any)
      )
      .on('mouseover', function () {
        handleMouseOver(this, svg);
      })
      .on('mouseout', () => {
        handleMouseOut(svg);
      })
      .on('mousemove', handleMouseMove)
      .on('mouseleave', handleMouseLeave);

    //* Grid ticks
    const tick = svg.append('g').attr('class', 'tick-group');
    tick
      .selectAll('.tick-rect')
      .data(range(1, rScaleSteps + 1))
      .enter()
      .append('text')
      .attr('class', 'tick-rect')
      .attr('x', 0)
      .attr('y', (d) => -rScale((d * maxValue) / rScaleSteps))
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .text((d) => Math.floor((maxValue / rScaleSteps) * d).toString())
      .each(function (d, i) {
        const textNode = select(this);
        const textBBox = textNode.node()?.getBBox() as DOMRect;
        const padding = {
          x: 0,
          y: 0
        };
        const rectWidth = textBBox.width + padding.x * 2;
        const rectHeight = textBBox.height + padding.y * 2;
        const rectX = textBBox.x - padding.x;
        const rectY = textBBox.y - padding.y;

        tick
          .append('rect')
          .attr('class', 'label-rect')
          .attr('x', rectX)
          .attr('y', rectY)
          .attr('width', rectWidth)
          .attr('height', rectHeight)
          .attr('rx', 4)
          .attr('ry', 4)
          .style('fill', '#f1f4f7');
      });

    // Tick label
    tick
      .selectAll('.tickl-abel')
      .data(range(1, rScaleSteps + 1))
      .enter()
      .append('text')
      .attr('class', 'tick-label')
      .attr('x', 0)
      .attr('y', (d) => -rScale((d * maxValue) / rScaleSteps))
      .attr('font-size', '12px')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .text((d) => axisTickFormatter(Math.floor((maxValue / rScaleSteps) * d)));
  }, [
    colorScale,
    currentWidget.data,
    chartData,
    handleMouseLeave,
    handleMouseMove,
    height,
    margin.bottom,
    margin.left,
    margin.right,
    margin.top,
    maxValue,
    rScale,
    svgContainer,
    width,
    xAxe,
    xScale,
    yAxe,
    yScale
  ]);

  if (_.isEmpty(chartData)) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <></>}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: 'recommendedWidgetsModal' }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS['polarAreaChart']?.length + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 && showLegend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="unit"
            legendValues={legendValues}
          />
        )}
      </HeaderWrapper>

      <SVGStyled
        ref={(node) => {
          svgRef.current = node;
          measureRef(node);
        }}
        width="100%"
        height="100%"
      ></SVGStyled>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
          />,
          document.body
        )}
    </>
  );
};
