// import { MultiSelect } from "react-multi-select-component";
import { ComparisonContent } from "./components/ComparisonContent";
import * as s from "./styles";

export const ComparisonMain = () => {
  return (
    <s.Container>
      {/* <div>
        <MultiSelect
          disableSearch
          ClearSelectedIcon={null}
          options={years}
          value={selected}
          onChange={handleSelectionChange}
          labelledBy="Select"
          valueRenderer={valueRenderer}
          ItemRenderer={itemRenderer}
          onMenuToggle={onMenuToggle}
          className="filter-multi-select"
        />
        <MultiSelect
          disableSearch
          ClearSelectedIcon={null}
          options={years}
          value={selected}
          onChange={handleSelectionChange}
          labelledBy="Select"
          valueRenderer={valueRenderer}
          ItemRenderer={itemRenderer}
          onMenuToggle={onMenuToggle}
          className="filter-multi-select"
        />
      </div> */}
      <ComparisonContent />
    </s.Container>
  );
};
