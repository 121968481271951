import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemContainer, PageWrapper } from "./styles";
import {
  getCurrentPageWidgets,
  getPageId,
} from "../../../../store/selectors/projects";
import { requestUpdateWidget } from "../../../../store/slices/widgets";
import { useDebounceCallback } from "usehooks-ts";
import { requestPageWidgets } from "../../../../store/slices/projectPages";
import { UpdateSingleWidgetDTO, WidgetItem } from "../../../../models/Widgets";
import SectionItem from "../StoryTellingSection/components/SectionItem/SectionItem";
import { requestComparisonWidgets } from "../../../../store/slices/comparison";
import {
  getComparisonLoading,
  getComparisonWidgets,
  getSelectedMeasure,
} from "../../../../store/selectors/comparison";

export const ComparisonContent: FC = () => {
  const dispatch = useDispatch();
  const pageWidgets = useSelector(getCurrentPageWidgets);
  const currentPageId = useSelector(getPageId);
  const selectedMeasure = useSelector(getSelectedMeasure);
  const comparisonWidgets = useSelector(getComparisonWidgets);
  const isLoading = useSelector(getComparisonLoading);

  const [mergedItems, setMergedItems] = useState<
    [WidgetItem, WidgetItem | null][]
  >([]);

  useEffect(() => {
    if (currentPageId && selectedMeasure) {
      dispatch(
        requestComparisonWidgets({
          pageId: currentPageId,
          measure: selectedMeasure,
        })
      );
    }
  }, [currentPageId, dispatch, selectedMeasure]);

  const handleSave = (widget: UpdateSingleWidgetDTO) => {
    dispatch(
      requestUpdateWidget({
        ...widget,
        blockId: String(widget?.blockId),
        callbacks: {
          onSuccess: () => {
            if (currentPageId) {
              dispatch(
                requestPageWidgets({
                  pageId: currentPageId,
                  includeData: true,
                })
              );
            }
          },
        },
      })
    );
  };

  const debouncedUpdateWidget = useDebounceCallback(handleSave, 500);

  useEffect(() => {
    // if (isLoading) return;
    if (pageWidgets.items && comparisonWidgets.items) {
      const processWidgets = (
        widgets: WidgetItem[],
        uniqueId: string
      ): WidgetItem[] =>
        widgets
          .map((el) => ({
            ...el,
            blockId: el.blockId || "1",
            sectionType: el.chartType === "mapChart" ? "map" : "widget",
            id: el.id,
            uniqueId,
          }))
          .sort((a, b) => Number(a.blockId) - Number(b.blockId));

      const originalWidgets = processWidgets(pageWidgets.items, "pageWidgets");
      const comparisonWidgetsProcessed = processWidgets(
        comparisonWidgets.items,
        "comparisonWidgets"
      );

      const merged: [WidgetItem, WidgetItem | null][] = originalWidgets.map(
        (originalWidget, index) => [
          originalWidget,
          comparisonWidgetsProcessed[index] || null,
        ]
      );

      setMergedItems(merged);
    }
  }, [pageWidgets.items, comparisonWidgets.items, isLoading]);

  return (
    <PageWrapper id="content">
      {mergedItems.map(([originalWidget, comparisonWidget], index) => (
        <ItemContainer key={originalWidget.id}>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flex: 1, marginRight: "5px" }}>
              <SectionItem
                key={originalWidget.id}
                setContentItem={debouncedUpdateWidget}
                item={originalWidget}
              />
            </div>
            <div style={{ flex: 1, marginLeft: "5px" }}>
              {comparisonWidget && (
                <SectionItem
                  key={comparisonWidget.id}
                  setContentItem={debouncedUpdateWidget}
                  item={comparisonWidget}
                />
              )}
            </div>
          </div>
        </ItemContainer>
      ))}
    </PageWrapper>
  );
};
